import Header from "../compo/items/Header";
import "../styles/main.scss";
import ListSearch from "../compo/templates/ListSearch";
import TopPageIntro from "../compo/items/TopPageIntro";
import PageTitle from "../compo/atoms/PageTitle";
import Input from "../compo/atoms/Input";
import ImageUpload from "../compo/items/ImageUpload";
import TextArea from "../compo/atoms/TextArea";
import Button from "../compo/atoms/Button";
import Select from "../compo/atoms/Select";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import PostForm from "../compo/templates/PostForm";
import countries from "../data/countires.json";
import PopupCard from "../compo/items/PopupCardCompany";

function DesignerSignup() {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };

  function validateForm(formData) {
    const error = {};
    // Check if all fields are present
    const requiredFields = ["email", "name"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        error[field] = `${field} field is required`;
      }
    });
    // Additional validation for specific fields (if needed)
    if (!validateEmail(formData["email"])) {
      error["email"] = `Invalid email format`;
    }
    return error;
  }
  function validateEmail(email) {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(validateForm(data));
    let x = validateForm(data);

    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length == 0) {
      const requestData = new FormData();
      requestData.append("email", data.email);
      requestData.append("name", data.name);
      axios
        .post("http://178.16.142.189:8002/api/designer/create", requestData, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        })
        .then((response) => {
          setData();
        })
        .catch((error) => {
          console.error("Error:", error.response.data);
        });
    }
  };

  return (
    <PopupCard
      handleChange={(res1, res2) => {
        handleChange(res1, res2);
      }}
      errors={errors}
      data={data}
      className={"popup_lightbox_white"}
      handleSubmit={() => handleSubmit()}
      signup={true}
    />
  );
}

export default DesignerSignup;
