import React, { useEffect, useState } from "react";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

export default function CompanyPic({ className, type, imgSrc, ...props }) {
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    if (imgSrc) {
      setImageError(false);
    }
  }, [imgSrc]);
  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className={`avatar_box ${className}`} {...props}>
      {!imageError && imgSrc && <img src={imgSrc} onError={handleImageError} />}
      {!imgSrc && (
        <>
          {type === "company" && (
            <div className="company_icon">
              <CustomIcon iconName="company" />
            </div>
          )}
          {type === "designer" && (
            <div className="company_icon">
              <CustomIcon iconName="company" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
