import "../../styles/main.scss";
import "../../styles/temp.scss";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import TextArea from "../atoms/TextArea";
import Button from "../atoms/Button";
import Select from "../atoms/Select";
import { useEffect } from "react";
import prefectures from "../../data/prefectures.json";
import { useDispatch, useSelector } from "react-redux";
// import { changeLanguage } from "../../redux/actions";
import pirsch from "../../Analytics";

export default function PostForm({
  data,
  errors,
  handleChange,
  handleSubmit,
  countries = [],
  setErrors,
  setData,
  setDesc,
  desc,
  handleKeyDown,
}) {
  const lang = useSelector((state) => state.lang);

  useEffect(() => {
    if (
      data?.remote?.value === "remote" ||
      data?.remote?.value === "リモート"
    ) {
      setErrors((prev) => {
        if (prev?.country || prev?.city) {
          delete prev?.country;
          delete prev?.city;
          delete prev?.prefecture;
        }
        return { ...prev };
      });
      setData((prev) => {
        if (lang == "en") {
          if (prev?.country || prev?.city) {
            delete prev?.country;
            delete prev?.city;
            delete prev?.prefecture;
          }
        } else {
          if (prev?.prefecture || prev?.city) {
            delete prev?.prefecture;
            delete prev?.city;
            delete prev?.country;
          }
        }

        return { ...prev };
      });
    }
  }, [data?.remote?.value]);

  return (
    // ----------------------------------------------------------------------------------
    <>
      {lang === "en" ? (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL
        <div className="job_info_input_box">
          <PageTitle
            sectionTitle="JOB INFO"
            text="Please be as detailed as possible describing the job opening."
          />
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="Job Title"
              options={[
                { label: "Product Designer", value: "Product Designer" },
                { label: "UXUI Designer", value: "UXUI Designer" },
                { label: "UX Designer", value: "UX Designer" },
                { label: "UI Designer", value: "UI Designer" },
                { label: "Graphic Designer", value: "Graphic Designer" },
                { label: "Animation Designer", value: "Animation Designer" },
                { label: "Branding Designer", value: "Branding Designer" },
              ]}
              setSelectedOption={(e) => handleChange("jobTitle", e)}
              selectedOption={data?.jobTitle}
              error={errors?.jobTitle}
            />
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="Job Type"
              options={[
                { label: "Full Time", value: "Full Time" },
                { label: "Part Time", value: "Part Time" },
                { label: "Contractor", value: "contractor" },
                { label: "Internship", value: "Internship" },
                { label: "Temporary", value: "Temporary" },
              ]}
              setSelectedOption={(e) => handleChange("jobType", e)}
              selectedOption={data?.jobType}
              error={errors?.jobType}
            />
          </div>
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" workspace_input"
              label="Workplace type"
              options={[
                { label: "Remote", value: "remote" },
                { label: "Hybrid", value: "hybrid" },
                { label: "Onsite", value: "onsite" },
              ]}
              selectedOption={data?.remote}
              error={errors?.remote}
              setSelectedOption={(e) => handleChange("remote", e)}
            />
            <Select
              placeholder="as"
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" "
              label={"Country"}
              options={countries}
              flag={true}
              selectedOption={data?.country}
              error={errors?.country}
              setSelectedOption={(e) => handleChange("country", e)}
              disable={data?.remote?.value === "remote" ? true : false}
              search={true}
              clearIcon={true}
              allOption={{ label: "All Countries", iconName: "flag" }}
            />

            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              placeholder="City"
              className="input_def signup_post_input"
              label="City"
              onChange={(e) => handleChange("city", e)}
              value={data?.city}
              error={errors?.city}
              disable={data?.remote?.value == "remote" ? true : false}
              maxLength={50}
            />
          </div>
          <div className="job_info_inputs_box">
            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              placeholder="Minimum salary"
              className="input_def salary_input_cont "
              innerClass="salary_input"
              label="Minimum salary"
              onChange={(e) => {
                if (!isNaN(e) || /^([0-9]+)$/.test(e)) {
                  handleChange("minimum_salary", e);
                }
              }}
              value={data?.minimum_salary}
              error={errors?.minimum_salary}
            />
            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              placeholder="Maximum salary "
              className="input_def salary_input_cont "
              innerClass="salary_input"
              label="Maximum salary"
              onChange={(e) => {
                if (!isNaN(e) || /^([0-9]+)$/.test(e)) {
                  handleChange("maximum_salary", e);
                }
              }}
              value={data?.maximum_salary}
              error={errors?.maximum_salary}
            />
          </div>
          <Input
            handleKeyDown={(e) => {
              handleKeyDown(e);
            }}
            placeholder="aaaaApplication URL or Public Email"
            className="input_def long_input"
            label="Application URL or Public Email"
            onChange={(e) => handleChange("endpoint", e)}
            value={data?.endpoint}
            error={errors?.endpoint}
            maxLength={50}
          />
          <TextArea
            label={"Job Description"}
            onChange={(e) => {
              setDesc(e);
            }}
            value={desc}
            error={errors?.job_description}
          />
          <div className="signup_save_button_cont">
            <Button
              text="Save & Subscribe"
              icon1="cart"
              icon2="cart"
              className="signup_save_button"
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      ) : (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN

        <div className="job_info_input_box">
          <PageTitle
            sectionTitle="求人情報"
            text="求人の詳細をできるだけ詳細に記述してください。"
          />
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="職種"
              options={[
                {
                  label: "プロダクトデザイナー",
                  value: "プロダクトデザイナー",
                },
                { label: "UXUIデザイナー", value: "UXUIデザイナー" },
                { label: "UXデザイナー", value: "UXデザイナー" },
                { label: "UIデザイナー", value: "UIデザイナー" },
                {
                  label: "グラフィックデザイナー",
                  value: "グラフィックデザイナー",
                },
                {
                  label: "アニメーションデザイナー",
                  value: "アニメーションデザイナー",
                },
                {
                  label: "ブランディンデザイナー",
                  value: "ブランディンデザイナー",
                },
              ]}
              setSelectedOption={(e) => handleChange("jobTitle", e)}
              selectedOption={data?.jobTitle}
              error={errors?.jobTitle}
            />
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="雇用形態"
              options={[
                { label: "正社員", value: "正社員" },
                { label: "アルバイト", value: "アルバイト" },
                { label: "業務委託", value: "業務委託" },
                { label: "インターン", value: "インターン" },
                { label: "案件", value: "案件" },
              ]}
              setSelectedOption={(e) => handleChange("jobType", e)}
              selectedOption={data?.jobType}
              error={errors?.jobType}
            />
          </div>
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" workspace_input"
              label="勤務地 (募集エリア)"
              options={[
                { label: "リモート", value: "リモート" },
                { label: "ハイブリッド", value: "ハイブリッド" },
                { label: "出社", value: "出社" },
              ]}
              selectedOption={data?.remote}
              error={errors?.remote}
              setSelectedOption={(e) => handleChange("remote", e)}
            />
            <Select
              placeholder="All"
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" "
              label="都道府県"
              options={prefectures}
              flag={true}
              selectedOption={data?.prefecture}
              error={errors?.prefecture}
              setSelectedOption={(e) => handleChange("prefecture", e)}
              disable={data?.remote?.value === "リモート" ? true : false}
              search={true}
              clearIcon={true}
              allOption={{ label: "都道府県を選ぶ", iconName: "map" }}
              searchList={prefectures}
            />

            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              className="input_def signup_post_input"
              label="市区町村 or 近い駅"
              placeholder="市区町村/近い駅を入力"
              onChange={(e) => handleChange("city", e)}
              value={data?.city}
              error={errors?.city}
              maxLength={50}
              disable={data?.remote?.value === "リモート" ? true : false}
            />
          </div>
          <div className="job_info_inputs_box">
            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              className="input_def salary_input_cont "
              innerClass="salary_inputs"
              label="最低額（月給）"
              onChange={(e) => {
                if (!isNaN(e) || /^([0-9]+)$/.test(e)) {
                  handleChange("minimum_salary", e);
                }
              }}
              value={data?.minimum_salary}
              error={errors?.minimum_salary}
              // pretext={"￥"}
              suftext={"円"}
            />
            <Input
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              className="input_def salary_input_cont "
              innerClass="salary_input"
              label="最高額（月給）"
              onChange={(e) => {
                if (!isNaN(e) || /^([0-9]+)$/.test(e)) {
                  handleChange("maximum_salary", e);
                }
              }}
              value={data?.maximum_salary}
              error={errors?.maximum_salary}
              // pretext={"〜"}
              suftext={"円"}
            />
          </div>
          <Input
            handleKeyDown={(e) => {
              handleKeyDown(e);
            }}
            placeholder=""
            className="input_def apply_link_input "
            label="求人応募先 or 連絡先メールアドレス"
            onChange={(e) => handleChange("endpoint", e)}
            value={data?.endpoint}
            error={errors?.endpoint}
            maxLength={50}
          />
          <TextArea
            label="仕事内容"
            placeholder=""
            onChange={(e) => {
              setDesc(e);
            }}
            value={desc}
            error={errors?.job_description}
            textStyle={true}
          />
          <div className="signup_save_button_cont">
            <Button
              text="登録する"
              icon1="send"
              icon2="send_ani"
              className="signup_save_button"
              onClick={() => {
                handleSubmit();
                pirsch("Entry - Signup - Clicked");
              }}
            />
          </div>
        </div>
      )}
    </>

    // -----------------------------------------------------------------------------
  );
}
