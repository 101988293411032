import { combineReducers } from "redux";

const languageReducer = (state = localStorage.getItem("lang"), action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return "en";
    default:
      return "en";
  }
};
const infoPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_INFO_POPUP":
      return action.payload;
    default:
      return state;
  }
};
const submitPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SUBMIT_POPUP":
      return action.payload;
    default:
      return state;
  }
};
const userLoginPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_USER_LOGIN_POPUP":
      return state ? false : true;
    default:
      return state;
  }
};
const companyLoginPopupReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_COMPANY_LOGIN_POPUP":
      return state ? false : true;
    default:
      return state;
  }
};
const designerReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_DESIGNER_DATA":
      return action.payload;
    default:
      return state;
  }
};
const companyReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_COMPANY_DATA":
      return action.payload;
    default:
      return state;
  }
};
const tokenReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return action.payload;
    default:
      return state;
  }
};
const userRoleReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_USER_ROLE":
      return action.payload;
    default:
      return state;
  }
};
const headerReducer = (state = true, action) => {
  switch (action.type) {
    case "SHOW_DEFAULT_HEADER":
      return action.payload;
    default:
      return state;
  }
};
const footerReducer = (state = true, action) => {
  switch (action.type) {
    case "SHOW_DEFAULT_FOOTER":
      return action.payload;
    default:
      return state;
  }
};
export default combineReducers({
  lang: languageReducer,
  infoPopupData: infoPopupReducer,
  submitPopupData: submitPopupReducer,
  showUserLoginPopup: userLoginPopupReducer,
  showCompanyLoginPopup: companyLoginPopupReducer,
  designerData: designerReducer,
  companyData: companyReducer,
  token: tokenReducer,
  userRole: userRoleReducer,
  ShowDefaultHeader: headerReducer,
  ShowDefaultFooter: footerReducer,
});
