import "../styles/main.scss";
import ChoosePlan from "../compo/items/ChoosePlan";

function ItemsView() {
  return (
    <>
      <ChoosePlan />
    </>
  );
}

export default ItemsView;
