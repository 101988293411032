import "../styles/main.scss";
import { ListSearch } from "../compo/templates/ListSearch";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import HeaderCompany from "../compo/items/HeaderCompany";
import { jwtDecode } from "jwt-decode";
import { BACKEND_URL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowDefaultHeader } from "../redux/actions";

function Company({ designerData }) {
  const dispatch = useDispatch();
  const ShowDefaultHeader = useSelector((state) => state.ShowDefaultHeader);
  const [filter, setFilter] = useState({});
  const lang = useSelector((state) => state.lang);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState();
  const [jobsNo, setJobsNo] = useState();
  const { username } = useParams();

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (filter) {
        setLoading(true);
        axios
          .get(`${BACKEND_URL}/api/posts`, {
            params: {
              username: username,
              type: filter?.jobType?.value,
              title: filter?.jobTitle?.value,
              country: filter?.country?.search,
              search: filter?.search,
              salary: filter?.salary,
              remote: [filter?.remote, filter?.hybrid, filter?.on_site],
              currency: filter?.currency?.value,
              prefecture: filter?.prefecture?.search,
              lang: lang,
              no_pagination: true,
            },
          })
          .then((response) => {
            if (response.data) {
              setData(response.data);
              setLoading(false);
            }
            setJobsNo(response.data.count);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error); // handle errors here
          });
      }
    }, 300); // Adjust the delay as needed (e.g., 1000 milliseconds = 1 second)

    return () => clearTimeout(debounceTimeout);
  }, [filter, lang, username]);
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/companies`, {
        params: {
          username: username,
        },
        headers: {},
      })
      .then((res) => {
        return res.data.results;
      })
      .then((data) => {
        setCompanyData(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, username]);
  useEffect(() => {
    dispatch(toggleShowDefaultHeader(false));
  }, [dispatch]);
  return (
    <>
      {!ShowDefaultHeader && (
        <>
          <HeaderCompany
            data={{ city: companyData?.city, poster: { ...companyData } }}
            jobsNo={jobsNo}
          />
          <ListSearch
            data={data}
            filter={filter}
            setFilter={(res) => setFilter(res)}
            jobsNo={jobsNo}
            companyPage={true}
            designer={designerData}
            loading={loading}
          />
        </>
      )}
    </>
  );
}

export default Company;
