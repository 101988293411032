import "../styles/main.scss";
import PopupCardUser from "../compo/items/PopupCardUser";
import PopupCardCompany from "../compo/items/PopupCardCompany";
import PopupCardPricing from "../compo/items/PopupCardPricing";
import PopupTemp from "../compo/templates/PopupTemp";

function ItemsView() {
  return (
    
   <>
<PopupCardUser/>
<PopupCardCompany/>
<PopupCardPricing/>
<PopupTemp
  showTitle={true}
  showImage={true}
  showText={true}
  showButtons={true}
  showButton1={true}
  showButton2={true}
  title="Popup Title"
  text="Popup Text"
  className="example-popup"
/>

</>

  );
}

export default ItemsView;
