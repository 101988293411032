import "../../styles/main.scss";
import "../../styles/temp.scss";
import axios from "axios";
import SearchCard from "./SearchCard";
// import CustomIcon from "../../data/IconCompo";
import PageTitle from "../atoms/PageTitle";
import JobsCardCompany from "./JobsCardCompany";
import JobsCard from "./JobsCard";
import { useEffect, useState } from "react";
import RadioButton from "../atoms/RadioBtn";
import PlanCard from "../atoms/PlanCard";
import Button from "../atoms/Button";

export default function ChoosePlan({ text, className }) {
  return (
    <div className={"content_box, plan_card_box"}>
      <PageTitle title="CHOOSE YOUR PLAN" />

      <PlanCard
        value="option1"
        iconUnchecked="radio_off"
        iconChecked="radio_on"
        planTitle="BASIC PLAN"
        planPrice="$99"
        planText="30 day job listing"
        className={"plan_radio_card_box"}
      />
      <PlanCard
        value="option1"
        iconUnchecked="radio_off"
        iconChecked="radio_on"
        planTitle="PRIME PLAN"
        planPrice="$130"
        planText="45 day job listing"
        className={"plan_radio_card_box"}
      />
      <Button text="Checkout" icon1="cart" icon2="cart" />
    </div>
  );
}
