import "../../styles/items.scss";

import Button from "../atoms/Button";

import CompanyCard from "./CompanyCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { BACKEND_URL } from "../../config";

import {
  showInfoPopup,
  showSubmitPopup,
  toggleShowUserLoginPopup,
  toggleShowCompanyLoginPopup,
  setDesignerData,
  setCompanyData,
} from "../../redux/actions";
import { getDesignerDataRequest } from "../../services/Designer";

export default function CompanyInfoDetailCard({
  fullName,
  endpoint,
  country,
  pref,
  data,
  page,
  inActive,
}) {
  const userRole = useSelector((state) => state.userRole);
  const dispatch = useDispatch();
  const submitPopupData = useSelector((state) => state.submitPopupData);
  const showUserLoginPopup = useSelector((state) => state.showUserLoginPopup);
  const designerData = useSelector((state) => state.designerData);
  useEffect(() => {
    console.log(designerData);
  }, [designerData]);
  let x = designerData;
  const [pinned, setPinned] = useState(
    designerData?.pins ? designerData?.pins?.includes(data?.id) : false
  );
  useEffect(() => {
    setPinned(
      designerData?.pins ? designerData?.pins?.includes(data?.id) : false
    );
  }, [designerData, data?.poster?.id]);

  function formatTimeDifference(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const diff = Math.round((now - time) / 1000); // Difference in seconds

    if (diff < 60) {
      return `${diff} Sec`;
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `${minutes} Min${minutes > 1 ? "s" : ""}`;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      const days = Math.floor(diff / 86400);
      return `${days} day${days > 1 ? "s" : ""}`;
    }
  }
  const handleApply = () => {
    axios
      .put(`${BACKEND_URL}/api/post/apply/${data?.id}`, {
        headers: {
          "Content-Type": "application/json", // Ensure correct content type
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        // Handle success response here
      })
      .catch((error) => {
        console.error("Error:", error.response.data);

        setPinned(!pinned);

        // Handle error response here
      });
    if (data?.endpoint.includes("@")) {
      window.location.href = `mailto:${data?.endpoint}`;
    } else {
      if (
        data?.endpoint.startsWith("http://") ||
        data?.endpoint.startsWith("https://")
      ) {
        // Open the external link in a new tab
        window.open(data?.endpoint, "_blank");
      } else {
        window.open(`https://${data?.endpoint}`, "_blank");
      }
    }
  };
  const handlePin = (event) => {
    if (designerData) {
      event.stopPropagation();

      // Define the data you want to send in the request
      if (designerData && data?.poster?.user?.id) {
        const requestData = {
          user_id: designerData?.user?.id, // Example user ID
          post_id: data?.id, // Example post ID
        };
        setPinned(!pinned);

        // Make a POST request using Axios

        getDesignerDataRequest(designerData?.user?.id)
          .then((res) => {
            dispatch(setDesignerData(res.data));
          })
          .catch((err) => {
            console.log(err);
          });
        axios
          .put(`${BACKEND_URL}/api/designer/pin`, requestData, {
            headers: {
              "Content-Type": "application/json", // Ensure correct content type
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
            },
          })
          .then((response) => {
            console.log("Response:", response.data);
            dispatch(showInfoPopup("success", response.data.message));
            // Handle success response here
          })
          .catch((error) => {
            console.error("Error:", error.response.data);
            dispatch(showInfoPopup("error", error.response.data.error));

            setPinned(!pinned);

            // Handle error response here
          });
      }
    } else {
      event.stopPropagation();
      dispatch(toggleShowUserLoginPopup());
    }
  };
  return (
    <div className="company_details_box">
      <div className="company_detail_info_box">
        <div className="company_2info_box">
          {/* <CompanyCard
            className={"company_pic_header_NameLocation smaller_pic"}
            showCompanyPic={true}
            showJobName={false}
            showJobInfo={false}
            showCompanyName={false}
            showCountry={false}
            title={title}
            type={type}
            minSalary={minSalary}
            maxSalary={maxSalary}
            company_name={company_name}
            country={country}
            city={city}
            remote={remote}
            fullName={fullName}
            imgSrc={`http://178.16.142.189:8002${imgSrc}`}
          /> */}

          <CompanyCard
            country={country}
            pref={pref}
            showCompanyPic={false}
            showJobName={true}
            showJobInfo={true}
            showCompanyName={false}
            showCountry={true}
            showFullName={fullName}
            className={"company_info_detail"}
            data={data}
          />
        </div>
        <div className="job_actions_box">
          {localStorage.getItem("user_role") !== "company" &&
            userRole !== "company" && (
              <Button
                icon1="pin_in"
                icon2="pin_off"
                className={`btn_c_icn pin_it ${pinned && "btn_c_icn_yellow"}`}
                onClick={(event) => handlePin(event)}
              />
            )}
        </div>
      </div>

      <div className="company_sns_box">
        {inActive ? (
          <div className="waiting_info">Waiting for approval</div>
        ) : (
          <Button
            text="APPLY"
            icon1="mail"
            icon2="mail_ani"
            onClick={(event) => handleApply(event)}
            className={"apply_btn"}
          />
        )}

        <div className="footer_sns_box">
          <span>{formatTimeDifference(data?.created_at)}</span>

          {/* <Nav
            link="/items"
            iconRight="twitter_line"
            className={"footer_sns_icon"}
          />
          <Nav
            link="/items"
            iconRight="facebook_line"
            className={"footer_sns_icon"}
          />
          <Nav
            link="/items"
            iconRight="linkedin_line"
            className={"footer_sns_icon"}
          />
          <Nav
            link="/items"
            iconRight="instagram_line"
            className={"footer_sns_icon"}
          /> */}
        </div>
      </div>
    </div>
  );
}
