// NavigationLink.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import CustomIcon from "../../data/IconCompo";

const Nav = ({
  name,
  className,
  link,
  icon,
  hoverIcon,
  iconRight,
  hoverIconRight,
  designerData,
  number,
  children,
  onClick = () => {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to={link}
      className={`nav_link ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onClick();
      }}
    >
      <CustomIcon
        iconName={isHovered && hoverIcon ? hoverIcon : icon}
        className={`nav_icon ${isHovered ? "hovered" : ""}`}
      />
      <span className="text_number">
        {name}
        {designerData ? (
          <span className="nav_number">{designerData?.pins?.length}</span>
        ) : (
          (number || number === 0) && (
            <span className="nav_number">{number}</span>
          )
        )}
      </span>
      <CustomIcon
        iconName={isHovered && hoverIconRight ? hoverIconRight : iconRight}
        className={`nav_icon_right ${isHovered ? "hovered" : ""}`}
      />
    </Link>
  );
};

Nav.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hoverIcon: PropTypes.string, // Updated to be optional
  iconRight: PropTypes.string.isRequired,
  hoverIconRight: PropTypes.string, // Updated to be optional
  number: PropTypes.string, // Corrected from Text to string
};

Nav.defaultProps = {
  className: "",
};

export default Nav;
