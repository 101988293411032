import "../../styles/items.scss";
import "../../styles/atoms.scss";
import CustomIcon from "../../data/IconCompo";
import CompanyPic from "../atoms/CompanyPic";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import CompanyCard from "./CompanyCard";
import StaticCard from "../atoms/StaticCard";
import PageTitle from "../atoms/PageTitle";
import Select from "../atoms/Select";
import countries from "../../data/countires.json";
import prefectures from "../../data/prefectures.json";
import { useEffect, useState } from "react";
import Companies from "../../pages/Companies";
import { useDispatch, useSelector } from "react-redux";

export default function SearchCard({
  className,
  companies,
  filter,
  setFilter,
  jobsNo,
  myjobs,
  page,
  adminTab,
  setAdminTab,
}) {
  const [countryDisable, setCountryDisable] = useState();

  const lang = useSelector((state) => state.lang);

  const handleChange = async (element, dataP) => {
    const newData = { ...filter, [`${element}`]: dataP };
    setFilter(newData);
    if (element === "remote") {
      if (dataP === false) {
        setFilter((prev) => {
          delete prev?.remote;
          return { ...prev };
        });
      }
    }
    if (element === "hybrid") {
      if (dataP === false) {
        setFilter((prev) => {
          delete prev?.hybrid;
          return { ...prev };
        });
      }
    }
    if (element === "on_site") {
      if (dataP === false) {
        setFilter((prev) => {
          delete prev?.on_site;
          return { ...prev };
        });
      }
    }
  };

  useEffect(() => {
    if (
      filter?.remote == "remote" &&
      filter?.hybrid !== "hybrid" &&
      filter?.on_site !== "on-site"
    ) {
      delete filter.country;
      setCountryDisable(true);
    } else {
      setCountryDisable(false);
    }
  }, [filter?.remote, filter?.hybrid, filter?.on_site]);

  useEffect(() => {
    if (countryDisable && filter?.country) {
    }
  }, [countryDisable]);

  return (
    <>
      {lang === "en" ? (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL

        <div
          className={`search_card_box ${
            page === "admin" && "search_card_box_admin"
          }`}
        >
          {page !== "admin" ? (
            <PageTitle
              titleNumber={jobsNo}
              title={companies ? "Companies" : "JOBS"}
            />
          ) : (
            <PageTitle title={"Search"} />
          )}
          {page === "admin" && (
            <div className="admin-tab-changer">
              <div
                className={`${
                  adminTab === "job" && "admin-tab-selected"
                } admin-tab-job unselectable`}
                onClick={() => {
                  setAdminTab("job");
                }}
              >
                Jobs
              </div>
              <div
                className={`${
                  adminTab === "company" && "admin-tab-selected"
                } admin-tab-company unselectable`}
                onClick={() => {
                  setAdminTab("company");
                }}
              >
                Companies
              </div>
            </div>
          )}
          <Input
            placeholder="Search"
            defaultIconName="search"
            focusedIconName="search_ani"
            className="input_def"
            onChange={(e) => handleChange("search", e)}
            value={filter?.search}
            clearIcon={true}
          />

          {!companies && adminTab !== "company" && (
            <>
              <div className="group_input_box">
                <Select
                  placeholder=""
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  className=""
                  label="Job Tile"
                  options={[
                    { label: "Product Designer", value: "Product Designer" },
                    { label: "UXUI Designer", value: "UXUI Designer" },
                    { label: "UX Designer", value: "UX Designer" },
                    { label: "UI Designer", value: "UI Designer" },
                    { label: "Graphic Designer", value: "Graphic Designer" },
                    {
                      label: "Animation Designer",
                      value: "Animation Designer",
                    },
                    { label: "Branding Designer", value: "Branding Designer" },
                  ]}
                  selectedOption={filter?.jobTitle}
                  setSelectedOption={(e) => handleChange("jobTitle", e)}
                  clearIcon={true}
                />
              </div>
            </>
          )}
          <div className="location_box">
            {!companies && adminTab !== "company" && (
              <>
                <label className="input-label">Location</label>

                <div className="location_radiobtn_box">
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="Remote"
                    setIsChecked={(checked) =>
                      handleChange("remote", checked ? "remote" : false)
                    }
                    isChecked={filter?.remote}
                  />
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="Hybrid"
                    setIsChecked={(checked) =>
                      handleChange("hybrid", checked ? "hybrid" : false)
                    }
                    isChecked={filter?.hybrid}
                  />
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="Onsite"
                    setIsChecked={(checked) =>
                      handleChange("on_site", checked ? "onsite" : false)
                    }
                    isChecked={filter?.on_site}
                  />
                </div>
              </>
            )}

            <Select
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              dropdownSelectClassName="country_inputs"
              className=" "
              label="Country"
              options={countries}
              flag={true}
              selectedOption={filter?.country}
              setSelectedOption={(e) => {
                handleChange("country", e);
              }}
              disable={countryDisable}
              search={true}
              clearIcon={true}
              allOption={{ label: "All Countries", iconName: "flag" }}
            />
          </div>
          {!companies && adminTab !== "company" && (
            <>
              <Select
                placeholder=""
                defaultIconName="drop_down"
                focusedIconName="drop_up"
                className=""
                label="Job Type"
                options={[
                  { label: "Full Time", value: "Full Time" },
                  { label: "Part Time", value: "Part Time" },
                  { label: "Contractor", value: "Contractor" },
                  { label: "Internship", value: "Internship" },
                  { label: "Temporary", value: "Temporary" },
                ]}
                selectedOption={filter?.jobType}
                setSelectedOption={(e) => handleChange("jobType", e)}
                clearIcon={true}
              />
              <div className="salary_box">
                <div className="group_input_box currency_box">
                  <Input
                    placeholder=""
                    className="input_def"
                    pretext={"$"}
                    label="Minimum Desired Salary"
                    innerClass="salary_inputs"
                    onChange={(e) => {
                      if (!isNaN(e)) {
                        handleChange("salary", e);
                      }
                    }}
                    value={filter?.salary}
                    clearIcon={true}
                  />
                </div>
              </div>
            </>
          )}
          <Button
            text="Clear"
            icon1="clear"
            icon2="clear_ani"
            className={"btn_c_line clear"}
            onClick={() => {
              setFilter({});
            }}
          />
        </div>
      ) : (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPANESE

        <div
          className={`search_card_box jp_text ${
            page === "admin" && "search_card_box_admin"
          }`}
        >
          {page !== "admin" ? (
            <PageTitle
              title={companies ? "会社" : "ジョブ"}
              titleNumber={jobsNo}
              className={"jp"}
            />
          ) : (
            <PageTitle title={"Search"} />
          )}
          {page === "admin" && (
            <div className="admin-tab-changer">
              <div
                className={`${
                  adminTab === "job" && "admin-tab-selected"
                } admin-tab-job unselectable`}
                onClick={() => {
                  setAdminTab("job");
                }}
              >
                Jobs
              </div>
              <div
                className={`${
                  adminTab === "company" && "admin-tab-selected"
                } admin-tab-company unselectable`}
                onClick={() => {
                  setAdminTab("company");
                }}
              >
                Companies
              </div>
            </div>
          )}
          <Input
            placeholder="検索"
            defaultIconName="search"
            focusedIconName="search_ani"
            className="input_def"
            onChange={(e) => handleChange("search", e)}
            value={filter?.search}
            clearIcon={true}
          />

          {!companies && adminTab !== "company" && (
            <>
              <div className="group_input_box">
                <Select
                  placeholder=""
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  label="職種"
                  options={[
                    {
                      label: "プロダクトデザイナー",
                      value: "プロダクトデザイナー",
                    },
                    { label: "UXUIデザイナー", value: "UXUIデザイナー" },
                    { label: "UXデザイナー", value: "UXデザイナー" },
                    { label: "UIデザイナー", value: "UIデザイナー" },
                    {
                      label: "グラフィックデザイナー",
                      value: "グラフィックデザイナー",
                    },
                    {
                      label: "アニメーションデザイナー",
                      value: "アニメーションデザイナー",
                    },
                    {
                      label: "ブランディンデザイナー",
                      value: "ブランディンデザイナー",
                    },
                  ]}
                  selectedOption={filter?.jobTitle}
                  setSelectedOption={(e) => handleChange("jobTitle", e)}
                  clearIcon={true}
                />
              </div>
            </>
          )}
          <div className="location_box">
            {!companies && adminTab !== "company" && (
              <>
                <label className="input-label">勤務地 (募集エリア)</label>

                <div className="location_radiobtn_box">
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="リモート"
                    setIsChecked={(checked) =>
                      handleChange("remote", checked ? "リモート" : false)
                    }
                    isChecked={filter?.remote}
                  />
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="ハイブリッド"
                    setIsChecked={(checked) =>
                      handleChange("hybrid", checked ? "ハイブリッド" : false)
                    }
                    isChecked={filter?.hybrid}
                  />
                  <CheckButton
                    iconUnchecked="check_off"
                    iconChecked="check_on"
                    label="出社"
                    setIsChecked={(checked) =>
                      handleChange("on_site", checked ? "出社" : false)
                    }
                    isChecked={filter?.on_site}
                  />
                </div>
              </>
            )}
            <Select
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              dropdownSelectClassName="country_inputs"
              className=" "
              label="都道府県"
              flag={true}
              options={prefectures}
              selectedOption={filter?.prefecture}
              setSelectedOption={(e) => handleChange("prefecture", e)}
              disable={countryDisable}
              search={true}
              clearIcon={true}
              allOption={{ label: "都道府県", iconName: "flag" }}
            />
          </div>
          {!companies && adminTab !== "company" && (
            <>
              <Select
                placeholder=""
                defaultIconName="drop_down"
                focusedIconName="drop_up"
                className=""
                label="雇用形態"
                options={[
                  { label: "正社員", value: "正社員" },
                  { label: "アルバイト", value: "アルバイト" },
                  { label: "業務委託", value: "業務委託" },
                  { label: "インターン", value: "インターン" },
                  { label: "案件", value: "案件" },
                ]}
                selectedOption={filter?.jobType}
                setSelectedOption={(e) => handleChange("jobType", e)}
                clearIcon={true}
              />
              <div className="salary_box">
                <div className="group_input_box currency_box">
                  <Input
                    placeholder=""
                    className="input_def"
                    // pretext={"￥"}
                    suftext={"円〜"}
                    label="最低給与（月次）"
                    innerClass="salary_inputs"
                    onChange={(e) => {
                      if (!isNaN(e)) {
                        handleChange("salary", e);
                      }
                    }}
                    value={filter?.salary}
                    clearIcon={true}
                  />
                </div>
              </div>
            </>
          )}
          <Button
            text="クリア"
            icon1="clear"
            icon2="clear_ani"
            className={"btn_c_line clear"}
            onClick={() => {
              setFilter({});
            }}
          />
        </div>
      )}
    </>
  );
}
