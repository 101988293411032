import "../../styles/main.scss";
import "../../styles/temp.scss";
import SearchCard from "../items/SearchCard";
// import CustomIcon from "../../data/IconCompo";
import PageTitle from "../atoms/PageTitle";
import JobsCard, { AdminCompaniesCard, AdminJobsCard } from "../items/JobsCard";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import { useSelector } from "react-redux";
import { incrementClicks } from "../../services/Post";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export function ListSearch({
  data,
  filter,
  setFilter,
  jobsNo,
  search = true,
  setJobsNo,
  setData,
  handlePagination,
  next,
  pagination,
  page,
  handleFunction,
  setNext,
  loading = true,
}) {
  const lang = useSelector((state) => state.lang);

  return (
    <>
      {lang === "en" ? (
        <div className="search_list_box">
          {search && (
            <SearchCard
              filter={filter}
              page={page}
              setFilter={(val) => setFilter(val)}
              jobsNo={jobsNo}
              companies={page === "companies"}
              myjobs={page === "myJobs"}
            />
          )}
          <div className="job_cards_list_cont">
            <div
              className={`${
                page !== "companies"
                  ? "job_cards_list_box"
                  : "company-cards-list-box"
              }`}
            >
              {!loading &&
                data &&
                data.map((el) => {
                  return (
                    <>
                      {page === "companies" ? (
                        <JobsCard
                          key={el.id}
                          data={{ As: "as", poster: { ...el } }}
                          page={page}
                          setData={(res) => setData(res)}
                          setJobsNo={(res) => setJobsNo(res)}
                          onClick={() => {
                            window.location = `/pinjob.me/${el?.user.username}`;
                          }}
                        />
                      ) : (
                        <JobsCard
                          key={el.id}
                          data={el}
                          setNext={(res) => {
                            setNext(res);
                          }}
                          page={page}
                          setJobsNo={(res) => setJobsNo(res)}
                          jobsNo={jobsNo}
                          setData={(res) => setData(res)}
                          onClick={() => {
                            if (page !== "myJobs") {
                              incrementClicks(el.id)
                                .then((response) => {
                                  window.location = `/job_detail/${el?.id}`;
                                })
                                .catch((err) => {
                                  console.log(err);
                                  window.location = `/job_detail/${el?.id}`;
                                });
                            } else {
                              localStorage.setItem("lang", el?.version);
                              window.location = el.is_approved
                                ? `/job_detail/${el?.id}`
                                : `/job_detail/in_active/${el?.id}`;
                            }
                          }}
                          handleFunction={(res) => {
                            handleFunction(res);
                          }}
                        />
                      )}
                    </>
                  );
                })}
              {loading && (
                <CustomIcon iconName={"loading_ani"} className={`empty-icon`} />
              )}
              {!loading && (!data || data?.length === 0) && (
                <div className="no-jobs-found">
                  <CustomIcon
                    iconName={page !== "pins" ? "illust_15" : "illust_12"}
                    className={`empty-icon`}
                  />
                  {page !== "pins" ? "NO JOB FOUND!" : "NO PINS FOUND!"}
                </div>
              )}
            </div>
            <div className="pagination-cont">
              {pagination &&
                (next
                  ? page !== "myJobs" && (
                      <Button
                        text={"MORE"}
                        icon1={"drop_down"}
                        className={"btn_c_line pagination-btn-more"}
                        onClick={() => {
                          handlePagination(next);
                        }}
                      />
                    )
                  : !loading &&
                    data?.length === jobsNo && (
                      <p className="no_more">
                        {/* <CustomIcon iconName="clear"/> */}
                      </p>
                    ))}
            </div>
          </div>
        </div>
      ) : (
        // ------------------------------------------------------ japan
        <div className="search_list_box">
          {search && (
            <SearchCard
              filter={filter}
              setFilter={(val) => setFilter(val)}
              jobsNo={jobsNo}
              companies={page === "companies"}
              myjobs={page === "myJobs"}
            />
          )}
          <div className="job_cards_list_cont">
            <div
              className={`${
                page !== "companies"
                  ? "job_cards_list_box"
                  : "company-cards-list-box"
              }`}
            >
              {data &&
                data.map((el) => {
                  return (
                    <>
                      {page === "companies" ? (
                        <JobsCard
                          key={el.id}
                          data={{ As: "as", poster: { ...el } }}
                          page={page}
                          setData={(res) => setData(res)}
                          setJobsNo={(res) => setJobsNo(res)}
                          onClick={() =>
                            (window.location = `/pinjob.me/${el?.user.username}`)
                          }
                        />
                      ) : (
                        <JobsCard
                          key={el.id}
                          data={el}
                          setNext={(res) => {
                            setNext(res);
                          }}
                          page={page}
                          setJobsNo={(res) => setJobsNo(res)}
                          jobsNo={jobsNo}
                          setData={(res) => setData(res)}
                          onClick={() => {
                            if (page !== "myJobs") {
                              incrementClicks(el.id)
                                .then((response) => {
                                  window.location = `/job_detail/${el?.id}`;
                                })
                                .catch((err) => {
                                  console.log(err);
                                  window.location = `/job_detail/${el?.id}`;
                                });
                            } else {
                              localStorage.setItem("lang", el?.version);
                              window.location = `/job_detail/in_active/${el?.id}`;
                            }
                          }}
                          handleFunction={(res) => {
                            handleFunction(res);
                          }}
                        />
                      )}
                    </>
                  );
                })}

              {data?.length === 0 && (
                <div className="no-jobs-found">
                  <CustomIcon iconName={"illust_15"} className={`empty-icon`} />
                  NO JOB FOUND!
                </div>
              )}
            </div>
            <div className="pagination-cont">
              {pagination &&
                (next
                  ? page !== "myJobs" && (
                      <Button
                        text={"MORE"}
                        icon1={"drop_down"}
                        className={"btn_c_line pagination-btn-more"}
                        onClick={() => {
                          handlePagination(next);
                        }}
                      />
                    )
                  : !loading &&
                    data?.length === jobsNo && (
                      <p className="no_more">
                        THE END
                        {/* <CustomIcon iconName="clear"/> */}
                      </p>
                    ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function AdminListSearch({
  data,
  filter,
  setFilter,
  jobsNo,
  search = true,
  setJobsNo,
  setData,
  handlePagination,
  next,
  pagination,
  page,
  handleFunction,
  setAdminTab,
  adminTab,
  loading,
}) {
  const lang = useSelector((state) => state.lang);
  const navigate = useNavigate();
  return (
    <>
      {lang === "en" ? (
        <div className="search_list_box">
          {search && (
            <SearchCard
              filter={filter}
              page={page}
              setFilter={(val) => setFilter(val)}
              jobsNo={jobsNo}
              companies={page === "companies"}
              myjobs={page === "myJobs"}
              setAdminTab={(res) => {
                setAdminTab(res);
              }}
              adminTab={adminTab}
            />
          )}
          <div className="job_cards_list_cont">
            <PageTitle
              title={adminTab === "job" ? "Jobs" : "COMPANIES"}
              titleNumber={jobsNo}
              page={page}
              setFilter={(val) => setFilter(val)}
              filter={filter}
              adminTab={adminTab}
            />

            <div
              className={`${
                page !== "companies"
                  ? "job_cards_list_box"
                  : "company-cards-list-box"
              }`}
            >
              {data &&
                !loading &&
                data.map((el) => {
                  return adminTab === "job" ? (
                    <AdminJobsCard
                      key={el.id}
                      data={el}
                      page={page}
                      setFilter={(val) => setFilter(val)}
                      setJobsNo={(res) => setJobsNo(res)}
                      jobsNo={jobsNo}
                      setData={(res) => setData(res)}
                      onClick={() => {
                        localStorage.setItem("lang", el?.version);
                        const admin = true;
                        navigate(`/job_detail/in_active/${el?.id}`, {
                          state: { admin },
                        });
                      }}
                      handlePagination={(res) => {
                        handlePagination(res);
                      }}
                      handleFunction={(res) => {
                        handleFunction(res);
                      }}
                      adminTab={adminTab}
                    />
                  ) : (
                    <AdminCompaniesCard
                      data={el}
                      adminTab={adminTab}
                      handleFunction={(res) => {
                        handleFunction(res);
                      }}
                      onClick={() => {
                        window.location = `pinjob.me/${el?.user?.username}`;
                      }}
                    />
                  );
                })}

              {data?.length === 0 && (
                <div className="no-jobs-found">
                  <CustomIcon iconName={"illust_15"} className={`empty-icon`} />
                  NO JOB FOUND!
                </div>
              )}
              {loading && (
                <CustomIcon iconName={"illust_15"} className={`empty-icon`} />
              )}
            </div>
            <div className="pagination-cont">
              {pagination &&
                (next
                  ? page !== "myJobs" && (
                      <Button
                        text={"MORE"}
                        icon1={"drop_down"}
                        className={"btn_c_line pagination-btn-more"}
                        onClick={() => {
                          handlePagination(next);
                        }}
                      />
                    )
                  : !loading &&
                    data?.length === jobsNo && (
                      <p className="no_more">
                        THE END
                        {/* <CustomIcon iconName="clear"/> */}
                      </p>
                    ))}
            </div>
          </div>
        </div>
      ) : (
        // ------------------------------------------------------ japan
        <div className="search_list_box">
          {search && (
            <SearchCard
              filter={filter}
              setFilter={(val) => setFilter(val)}
              jobsNo={jobsNo}
              companies={page === "companies"}
              myjobs={page === "myJobs"}
              page={page}
              setAdminTab={(res) => {
                setAdminTab(res);
              }}
              adminTab={adminTab}
            />
          )}
          <div className="job_cards_list_cont">
            <PageTitle
              title={adminTab === "job" ? "マイピン" : "COMPANIES"}
              titleNumber={jobsNo}
              className={"jp_text"}
              page={page}
              setFilter={(val) => setFilter(val)}
              filter={filter}
              adminTab={adminTab}
            />
            <div
              className={`${
                page !== "companies"
                  ? "job_cards_list_box"
                  : "company-cards-list-box"
              }`}
            >
              {data &&
                !loading &&
                data.map((el) => {
                  return adminTab === "job" ? (
                    <AdminJobsCard
                      key={el.id}
                      data={el}
                      page={page}
                      setFilter={(val) => setFilter(val)}
                      setJobsNo={(res) => setJobsNo(res)}
                      jobsNo={jobsNo}
                      setData={(res) => setData(res)}
                      onClick={() => {
                        localStorage.setItem("lang", el?.version);
                        window.location = `/job_detail/in_active/${el?.id}`;
                      }}
                      handlePagination={(res) => {
                        handlePagination(res);
                      }}
                      handleFunction={(res) => {
                        handleFunction(res);
                      }}
                      adminTab={adminTab}
                    />
                  ) : (
                    <AdminCompaniesCard
                      data={el}
                      adminTab={adminTab}
                      handleFunction={(res) => {
                        handleFunction(res);
                      }}
                      onClick={() => {
                        window.location = `pinjob.me/${el?.user?.username}`;
                      }}
                    />
                  );
                })}

              {data?.length === 0 && (
                <div className="no-jobs-found">
                  <CustomIcon iconName={"illust_15"} className={`empty-icon`} />
                  NO JOB FOUND!
                </div>
              )}
              {loading && (
                <CustomIcon iconName={"illust_15"} className={`empty-icon`} />
              )}
            </div>
            <div className="pagination-cont">
              {pagination &&
                (next
                  ? page !== "myJobs" && (
                      <Button
                        text={"MORE"}
                        icon1={"drop_down"}
                        className={"btn_c_line pagination-btn-more"}
                        onClick={() => {
                          handlePagination(next);
                        }}
                      />
                    )
                  : !loading &&
                    data?.length === jobsNo && (
                      <p className="no_more">
                        THE END
                        {/* <CustomIcon iconName="clear"/> */}
                      </p>
                    ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
