import "../styles/main.scss";

import { ListSearch } from "../compo/templates/ListSearch";

import axios from "axios";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../config";
import { useSelector } from "react-redux";

function Companies() {
  const lang = useSelector((state) => state.lang);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState();
  const [next, setNext] = useState();
  const [jobsNo, setJobsNo] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BACKEND_URL}/api/companies`, {
        params: {
          search: filter.search,
          country: filter?.country?.search,
          prefecture: filter?.prefecture?.search,
          lang: lang,
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.results);
          setLoading(false);
          setNext(response.data.next);
          setJobsNo(response.data.count);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error); // handle errors here
      });
  }, [filter, lang]);

  const handlePagination = (link) => {
    axios
      .get(next, {
        params: {
          search: filter.search,
          country: filter?.country?.search,
          prefecture: filter?.prefecture?.search,
          lang: lang,
        },
      })
      .then((response) => {
        if (response.data.results) {
          setData((prev) => {
            const prevIds = prev.map((el) => {
              return el.user.id;
            });
            const newIds = response.data.results.map((el) => {
              return el.user.id;
            });

            if (!prevIds.includes(newIds[0])) {
              prev.push(...response.data.results);
              setNext(response.data.next);
              setJobsNo(response.data.count);
            }

            return [...prev];
          });
          setNext(response.data.next);
          setJobsNo(response.data.count);
        }
      })
      .catch((error) => {
        console.error(error); // handle errors here
      });
  };

  return (
    <>
      <ListSearch
        data={data}
        filter={filter}
        setFilter={(val) => setFilter(val)}
        jobsNo={jobsNo}
        page={"companies"}
        handlePagination={(res) => {
          handlePagination(res);
        }}
        next={next}
        pagination={true}
        loading={loading}
      />
    </>
  );
}

export default Companies;
