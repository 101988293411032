import axios from "axios";
import { BACKEND_URL } from "../config";

export const CreateCompany = async (data, image) => {
  const requestData = new FormData();
  requestData.append("email", data.email);
  requestData.append("username", data.username);
  requestData.append("company_name", data.company);
  requestData.append("name", data.name);
  requestData.append("url", data.url);
  requestData.append("country", data.country_user.label);
  requestData.append("city", data.city_user);
  requestData.append("image", image);

  return await axios.post(`${BACKEND_URL}/company/create`, requestData, {});
};

export const CreateCompanyPost = async (
  data,
  image,
  desc,
  preRelease = "",
  lang = "en"
) => {
  const requestData = new FormData();
  requestData.append("email", data.email);
  requestData.append("username", data.username.toLowerCase());
  requestData.append("company_name", data.company);
  requestData.append("name", data.name);
  requestData.append("url", data.url);
  if (lang === "en") {
    requestData.append("country_user", data.country_user?.search);
  } else {
    requestData.append("prefecture_user", data.prefecture_user?.search);
  }
  requestData.append("city_user", data?.city_user);
  requestData.append("image", image);
  requestData.append("title", data.jobTitle.label);
  requestData.append("type", data.jobType.label);
  requestData.append("endpoint", data.endpoint);
  requestData.append("name", data.name);
  requestData.append("remote", data.remote.value);
  requestData.append("currency", "url");
  requestData.append("minimum_salary", data.minimum_salary);
  requestData.append("maximum_salary", data.maximum_salary);
  requestData.append("job_description", desc);
  requestData.append("pre_release", preRelease);
  requestData.append("lang", lang);

  if (data?.remote?.value !== "remote" && data?.remote?.value !== "リモート") {
    if (lang === "en") {
      requestData.append("country", data?.country?.search);
      requestData.append("prefecture", "nodata");
    } else {
      requestData.append("prefecture", data.prefecture?.search);
      requestData.append("country", "japan");
    }
    requestData.append("city", data?.city);
  }

  return await axios.post(
    `${BACKEND_URL}/api/company/post/create`,
    requestData,
    {}
  );
};

export const getCompanyDataRequest = async (admin) => {
  return await axios.get(`${BACKEND_URL}/api/companies`, {
    params: {
      me: true,
    },
    headers: {
      Authorization: admin
        ? `Bearer ${localStorage.getItem("admin_token")}`
        : `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
