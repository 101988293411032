import React from "react";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

export default function PriceCard({ text1, text2, price, className, iconName, plan }) {
  return (
    <div className={`price_card ${className}`}>
     {plan}
      <CustomIcon iconName={iconName} />
      <h8>{price}</h8>
      <span><h9>{text1}</h9>{text2}</span>
    </div>
  );
}
