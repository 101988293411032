// NavigationLink.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import PopupTab from "../atoms/PopupTab";
import PriceCard from "../atoms/PricingCard";
import Nav from "../atoms/Nav";


const PopupCardPricing = ({
  title,
  text,
  titleNumber,
  className,
  sectionTitle,
}) => {
  return (
    <div className="popup_lightbox">
      <div className="popup_box pricing">
        <div className="popup_close_box">
          <Button icon1="close" icon2="cart" className={"btn_c_icn"} />
        </div>

        <div className="popup_title_box">
        PRICING
          <CustomIcon iconName="money" />
        </div>

        <div className="popup_content_box">
          <CustomIcon iconName="illust_12" className="illus" />

          <div className="form_box">
            <PageTitle text="A creative jobs board that works for everyone. Built by Designers, for Designers." />
          </div>
          <div className="price_cards_box">
            <PriceCard
              plan="30 Days Plan"
              iconName="money"
              text1="30"
              text2="day job listing "
              price="$99"
            />
            <PriceCard
              plan="45 Days Plan"
              iconName="cal"
              text1="45"
              text2="day job listing "
              price="$120"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupCardPricing;
