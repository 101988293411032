import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../config";
import { useSelector } from "react-redux";

export default function HeaderAdmin({}) {
  const lang = useSelector((state) => state.lang);
  const infoPopupData = useSelector((state) => state.infoPopupData);
  const [panelData, setPanelData] = useState();
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/admin/panel`, {
        headers: {
          "Content-Type": "application/json", // Ensure correct content type
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setPanelData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [infoPopupData]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1) {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "120px";
          el.style.borderBottom = "2px solid #00000010";
        });

        // document.querySelector(".header-mockup").style.display = "block";
      } else {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "140px";
          el.style.borderBottom = "1px solid transparent";
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <>
        <div className="header_box admin-header">
          <div
            className="logo_box logo_box_admin"
            onClick={() => {
              window.location = "/";
            }}
          >
            <CustomIcon iconName="logo_mark" className="logo_mark" />
            <CustomIcon iconName="logo_type" />
          </div>
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL-  */}

          {lang === "en" ? (
            <div className="admin-panel">
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"clicks"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">Apply</p>
                <p className="admin-panel-info-number">{panelData?.applies}</p>
              </div>
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"views"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">View</p>
                <p className="admin-panel-info-number">{panelData?.views}</p>
              </div>
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"pin_in"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">Pins</p>
                <p className="admin-panel-info-number">{panelData?.pins}</p>
              </div>
              <div className="admin-panel-info-cont border-black">
                <CustomIcon
                  iconName={"company"}
                  className="admin-panel-info-icon color-black"
                />
                <p className="admin-panel-info-title">Comps</p>
                <p className="admin-panel-info-number">
                  {panelData?.active_companies_number} /{panelData?.companies}
                </p>
              </div>
              <div className="admin-panel-info-cont border-black">
                <CustomIcon
                  iconName={"jobs"}
                  className="admin-panel-info-icon color-black"
                />
                <p className="admin-panel-info-title">Jobs</p>
                <p className="admin-panel-info-number">
                  {panelData?.active_jobs}/{panelData?.jobs}
                </p>
              </div>
            </div>
          ) : (
            // - - - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - JAPAN
            <div className="admin-panel">
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"clicks"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">Apply</p>
                <p className="admin-panel-info-number">{panelData?.applies}</p>
              </div>
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"views"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">View</p>
                <p className="admin-panel-info-number">{panelData?.views}</p>
              </div>
              <div className="admin-panel-info-cont">
                <CustomIcon
                  iconName={"pin_in"}
                  className="admin-panel-info-icon"
                />
                <p className="admin-panel-info-title">Pins</p>
                <p className="admin-panel-info-number">{panelData?.pins}</p>
              </div>
              <div className="admin-panel-info-cont border-black">
                <CustomIcon
                  iconName={"company"}
                  className="admin-panel-info-icon color-black"
                />
                <p className="admin-panel-info-title">Comps</p>
                <p className="admin-panel-info-number">
                  {panelData?.active_companies_number} /{panelData?.companies}
                </p>
              </div>
              <div className="admin-panel-info-cont border-black">
                <CustomIcon
                  iconName={"jobs"}
                  className="admin-panel-info-icon color-black"
                />
                <p className="admin-panel-info-title">Jobs</p>
                <p className="admin-panel-info-number">
                  {panelData?.active_jobs}/{panelData?.jobs}
                </p>
              </div>
            </div>
          )}

          {/* <Button
          text={lang}
          className={"btn_def"}
          onClick={() => {
            dispatch(changeLanguage(lang == "en" ? "jp" : "en"));
          }}
        /> */}
        </div>
        <div className="header-mockup"></div>
      </>
    </>
  );
}
