import "../styles/main.scss";
import { ListSearch } from "../compo/templates/ListSearch";
import { BACKEND_URL } from "../config";
import axios from "axios";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import PageTitle from "../compo/atoms/PageTitle";
import { useSelector } from "react-redux";

function MyJobs() {
  const [filter, setFilter] = useState({});
  const [data, setData] = useState();
  const [jobsNo, setJobsNo] = useState();
  const [loading, setLoading] = useState();
  const lang = useSelector((state) => state.lang);
  const companyData = useSelector((state) => state.companyData);

  useEffect(() => {
    if (companyData?.user?.id) {
      setLoading(true);
      axios
        .get(`${BACKEND_URL}/api/posts`, {
          params: {
            poster_id: companyData?.user?.id,
            all: true,
            no_pagination: true,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setData(data);
          setLoading(false);
          setJobsNo(data.length);
        })
        .catch((error) => {
          console.error(error); // handle errors here
          setLoading(false);
        });
    }
  }, [companyData]);

  const handleFunction = async () => {
    const pageIndex = Math.ceil(data.length / 20);
    let link = `${BACKEND_URL}/api/posts`;
    let dataStore = [];
    for (let i = 0; i < pageIndex; i++) {
      try {
        const response = await axios.get(link, {
          params: {
            poster_id: jwtDecode(localStorage.getItem("token"))?.user_id,
            all: true,
            no_pagination: true,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
          },
        });

        if (response.data.results) {
          dataStore.push(...response.data.results);
          setJobsNo(response.data.count);
        }
      } catch (error) {
        console.error(error); // handle errors here
      }
    }
    setData(dataStore);
  };
  useEffect(() => {
    if (localStorage.getItem("user_role") !== "company") {
      window.location = "/404";
    }
  }, []);
  return (
    <>
      {" "}
      {localStorage.getItem("user_role") === "company" && (
        <div className="page_section_box_short">
          {lang === "en" ? (
            <PageTitle title="MY JOBS" titleNumber={jobsNo} />
          ) : (
            <PageTitle
              title="マイジョブ"
              titleNumber={jobsNo}
              className={"jp_text"}
            />
          )}
          <ListSearch
            data={data}
            filter={filter}
            setFilter={(val) => setFilter(val)}
            jobsNo={jobsNo}
            search={false}
            page={"myJobs"}
            setData={(res) => setData(res)}
            setJobsNo={(res) => setJobsNo(res)}
            handleFunction={() => {
              handleFunction();
            }}
            loading={loading}
          />
        </div>
      )}
    </>
  );
}

export default MyJobs;
