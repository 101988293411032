import "../../styles/main.scss";
// import CustomIcon from "../../data/IconCompo";

export default function Tag({ text, className }) {

  return (
    <div className={`tag_def ${className}`}>
      {text}
      {/* <CustomIcon iconName={"hand4"} /> */}
    </div>
  );
}
