import Header from "../compo/items/Header";
import "../styles/main.scss";
import ListSearch from "../compo/templates/ListSearch";
import TopPageIntro from "../compo/items/TopPageIntro";
import PageTitle from "../compo/atoms/PageTitle";
import Input from "../compo/atoms/Input";
import ImageUpload from "../compo/items/ImageUpload";
import TextArea from "../compo/atoms/TextArea";
import Button from "../compo/atoms/Button";
import Select from "../compo/atoms/Select";
import { useEffect, useRef, useState, version } from "react";
import axios, { all } from "axios";
import PostForm from "../compo/templates/PostForm";
import countries from "../data/countires.json";
import { CreatePost, updatePost } from "../services/Post";
import { useDispatch, useSelector } from "react-redux";
import Separator from "../compo/atoms/Separator";
import prefectures from "../data/prefectures.json";
import { showInfoPopup, showSubmitPopup } from "../redux/actions";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../config";
import { jwtDecode } from "jwt-decode";
function PostCreate({}) {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const { postId } = useParams();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [desc, setDesc] = useState();
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of form submission
      handleSubmit();
    }
  };
  useEffect(() => {
    if (postId) {
      axios
        .get(`${BACKEND_URL}/api/posts`, {
          params: {
            id: postId,
            poster_id: jwtDecode(localStorage.getItem("token"))?.user_id,
            all: true,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
          },
        })
        .then((response) => {
          if (response.data) {
            if (response.data.results.length === 0) {
              window.location = "/404";
            } else {
              setData(
                lang === "en"
                  ? {
                      company: response.data.results[0].poster.company_name,
                      endpoint: response.data.results[0].endpoint,

                      jobType: {
                        label: response.data.results[0].type,
                        value: response.data.results[0].type,
                      },
                      city: response.data.results[0].city,
                      remote: {
                        label: response.data.results[0].remote,
                        value: response.data.results[0].remote,
                      },
                      jobTitle: {
                        label: response.data.results[0].title,
                        value: response.data.results[0].title,
                      },
                      maximum_salary: response.data.results[0].maximum_salary,
                      minimum_salary: response.data.results[0].minimum_salary,
                      country:
                        response.data.results[0].country &&
                        findItemByKey(
                          countries,
                          "label",
                          capitalizeFirstLetter(
                            response.data.results[0].country
                          )
                        ),
                    }
                  : {
                      company: response.data.results[0].poster.company_name,
                      endpoint: response.data.results[0].endpoint,
                      prefecture: findItemByKey(
                        prefectures,
                        "label",
                        response.data.results[0].prefecture
                      ),
                      remote: {
                        label: response.data.results[0].remote,
                        value: response.data.results[0].remote,
                      },
                      city: response.data.results[0].city,
                      jobType: {
                        label: response.data.results[0].type,
                        value: response.data.results[0].type,
                      },
                      jobTitle: {
                        label: response.data.results[0].title,
                        value: response.data.results[0].title,
                      },
                      maximum_salary: response.data.results[0].maximum_salary,
                      minimum_salary: response.data.results[0].minimum_salary,
                    }
              );
              setDesc(response.data.results[0].job_description);
            }
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
        });
    } else {
      axios
        .get(`${BACKEND_URL}/api/companies`, {
          params: {
            me: true,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setData(
              lang === "en"
                ? {
                    ...response.data.results[0],
                    company: response.data.results[0].company_name,
                    url: response.data.results[0].url,
                    id: response.data.results[0].user.id,
                    country: findItemByKey(
                      countries,
                      "label",
                      response.data.results[0].country
                    ),
                  }
                : {
                    ...response.data.results[0],
                    company: response.data.results[0].company_name,
                    url: response.data.results[0].url,
                    id: response.data.results[0].user.id,
                    prefecture: findItemByKey(
                      prefectures,
                      "label",
                      response.data.results[0].prefecture
                    ),
                  }
            );

            setImagePreview(`${BACKEND_URL}${response.data.results[0].image}`);
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
        });
    }
  }, []);

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);

    if (errors[element]) {
      errors[element] = false;
    }
  };
  function findItemByKey(array, key, value) {
    if (value) {
      return array.find(
        (item) => item[key].toLowerCase() === value.toLowerCase()
      );
    } else {
      return "";
    }
  }

  function validateEndpoint(value) {
    // Regular expression to validate URL
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the value is a valid URL or email
    return urlRegex.test(value) || emailRegex.test(value);
  }
  function validateForm(formData) {
    const error = {};
    let requiredFields = {};
    if (
      data?.remote?.value === "remote" ||
      data?.remote?.value === "リモート"
    ) {
      requiredFields = [
        "jobTitle",
        "jobType",
        //"country_user",
        //"city_user",
        "endpoint",
        "remote",
        "minimum_salary",
        "maximum_salary",

        "job_description",
        //"name",
        //"username",
        //"email",
        //"url",
        //"image",
      ];
    } else {
      if (lang === "en") {
        requiredFields = [
          "jobTitle",
          "jobType",
          //"country_user",
          //"city_user",
          "endpoint",
          "remote",
          "minimum_salary",
          "maximum_salary",

          "job_description",
          //"name",
          //"username",
          //"email",
          //"url",
          //"image",
          "country",
          "city",
        ];
      } else {
        requiredFields = [
          "jobTitle",
          "jobType",
          //"country_user",
          //"city_user",
          "endpoint",
          "remote",
          "minimum_salary",
          "maximum_salary",
          "job_description",
          //"name",
          //"username",
          //"email",
          //"url",
          //"image",
          "prefecture",
          "city",
        ];
      }
    }

    // Check if all fields are present

    requiredFields.forEach((field) => {
      if (field === "image" && !image) {
        error[field] = `${field} field is required`;
      } else if (field === "job_description" && !desc) {
        error.job_description = `job_description field is required`;
      } else if (
        !formData[field] &&
        field !== "image" &&
        field !== "job_description"
      ) {
        error[field] = `${field} field is required`;
      }
    });

    // Additional validation for specific fields (if needed)
    if (formData.maximum_salary && isNaN(formData.maximum_salary)) {
      error.maximum_salary = "Maximum salary must be a number";
    }
    if (formData.minimum_salary && isNaN(formData.minimum_salary)) {
      error.minimum_salary = "Minimum salary must be a number";
    }
    if (parseInt(formData.maximum_salary) < parseInt(formData.minimum_salary)) {
      error.maximum_salary = "Maximum salary must be a number";
      error.minimum_salary = "Minimum salary must be a number";
    }
    if (
      !formData?.country?.search &&
      formData?.remote?.value !== "remote" &&
      formData?.remote?.value !== "リモート"
    ) {
      error.country =
        lang === "en"
          ? "Country field is required"
          : `都道府県を選択してください。`;
    }
    if (!validateEndpoint(formData.endpoint)) {
      error.endpoint = "Endpoint must be an endpoint or url";
    }
    console.log(error);
    return error;
  }

  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(validateForm(data));
    let x = validateForm(data);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length == 0) {
      if (postId) {
        updatePost(
          postId,
          data?.remote?.value === "remote" || data?.remote?.value === "リモート"
            ? {
                title: data.jobTitle.label,
                type: data.jobType.label,
                endpoint: data.endpoint,
                remote: data.remote.value,
                minimum_salary: data.minimum_salary,
                maximum_salary: data.maximum_salary,
                job_description: desc,
                country: null,
                prefecture: null,
                city: null,
                version: lang,
              }
            : lang === "en"
            ? {
                title: data.jobTitle.label,
                type: data.jobType.label,
                country: data.country.label,
                city: data.city,
                endpoint: data.endpoint,
                remote: data.remote.value,
                minimum_salary: data.minimum_salary,
                maximum_salary: data.maximum_salary,
                job_description: desc,
                version: lang,
              }
            : {
                title: data.jobTitle.label,
                type: data.jobType.label,
                prefecture: data.prefecture.label,
                city: data.city,
                endpoint: data.endpoint,
                remote: data.remote.value,
                minimum_salary: data.minimum_salary,
                maximum_salary: data.maximum_salary,
                job_description: desc,
                version: lang,
              }
        )
          .then((response) => {
            localStorage.removeItem("myJobs");
            dispatch(
              showSubmitPopup(
                lang === "en" ? "DONE" : "ありがとうございます。",
                lang === "en"
                  ? "The post has been successfully created and is pending approval."
                  : "ご協力をありがとうございます。投稿が正常に完了しました。",
                "",
                () => {
                  window.location.reload();
                }
              )
            );
          })
          .catch((error) => {
            console.error("Error:", error.response.data);
          });
      } else {
        CreatePost(data, desc, lang)
          .then((response) => {
            setData({ company: data.company, url: data.url });
            setDesc();
            dispatch(
              showSubmitPopup(
                lang === "en" ? "DONE" : "ありがとうございます。",
                lang === "en"
                  ? "The post has been successfully created and is pending approval."
                  : "ご協力をありがとうございます。投稿が正常に完了しました。",
                "",
                () => {
                  window.location.reload();
                }
              )
            );
          })
          .catch((error) => {
            console.error("Error:", error.response.data);
          });
      }
    } else {
      if (Object.keys(x).length === 1) {
        dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
      } else if (Object.keys(x).length >= 1) {
        dispatch(
          showInfoPopup(
            "error",
            lang === "en"
              ? "Please fill required fields with valid data"
              : "必須項目を適切に入力してください。 "
          )
        );
      }
    }
  };
  useEffect(() => {
    if (image) {
      setErrors((prev) => {
        delete prev.image;
        return { ...prev };
      });
    }
  }, [image]);
  useEffect(() => {
    if (localStorage.getItem("user_role") !== "company") {
      window.location = "/404";
    }
  }, []);
  return (
    <>
      {localStorage.getItem("user_role") === "company" && (
        <div className="page_section_box">
          {lang === "en" ? (
            <PageTitle title="Create New JOB Posting" />
          ) : (
            <PageTitle title="新しい求人情報を作成" className={"jp_text"} />
          )}
          <Separator />

          <PostForm
            handleKeyDown={(e) => {
              handleKeyDown(e);
            }}
            countries={countries}
            data={data}
            errors={errors}
            handleChange={(res1, res2) => {
              handleChange(res1, res2);
            }}
            handleSubmit={() => handleSubmit()}
            setErrors={(res) => {
              setErrors(res);
            }}
            setData={(res) => {
              setData(res);
            }}
            desc={desc}
            setDesc={(res) => {
              setDesc(res);
              errors.job_description = false;
            }}
          />
        </div>
      )}
    </>
  );
}

export default PostCreate;
