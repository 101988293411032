import "../styles/main.scss";
import PageTitle from "../compo/atoms/PageTitle";
import Input from "../compo/atoms/Input";
import ImageUpload from "../compo/items/ImageUpload";
import Select from "../compo/atoms/Select";
import { useEffect, useState } from "react";
import PostForm from "../compo/templates/PostForm";
import Separator from "../compo/atoms/Separator";
import countries from "../data/countires.json";
import { CreateCompanyPost } from "../services/Company";

import { signupValidation } from "../validation";
import { useDispatch, useSelector } from "react-redux";
import prefectures from "../data/prefectures.json";
import {
  showInfoPopup,
  showSubmitPopup,
  toggleShowDefaultFooter,
  toggleShowDefaultHeader,
} from "../redux/actions";

function Signup() {
  // React state --------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const [desc, setDesc] = useState();
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const lang = useSelector((state) => state.lang);
  const token = useSelector((state) => state.token);

  // Changing the input fields using state ----------------------------------------------------------

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of form submission
      handleSubmit();
    }
  };

  // validation checkers -----------------------------------------------------------------

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  // submitting the form ---------------------------------------------------------------------------------------------

  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(signupValidation(data, desc, image, lang));
    let x = signupValidation(data, desc, image, lang);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      dispatch(
        showInfoPopup("loading", "Connecting!...", 20000, "right_arrow_ani")
      );
      CreateCompanyPost(data, image, desc, "", lang)
        .then((res) => {
          setData({});
          setDesc({});
          dispatch(showInfoPopup(false));
          setImage();
          setImagePreview();
          dispatch(
            showSubmitPopup(
              lang === "en" ? "Congratulations!" : "ありがとうございます。",
              lang === "en"
                ? "Your account is created, and your post is pending approval. Access your account via the link sent to your email."
                : "ご協力をありがとうございます。投稿が正常に完了しました。",
              "illust_12",
              () => {
                window.location.reload();
              }
            )
          );
        })
        .catch((error) => {
          if (error?.response?.data.error) {
            dispatch(showInfoPopup("error", error?.response?.data.error));
            let errorFields = error?.response?.data?.fields;
            if (errorFields) {
              errorFields.forEach((element) => {
                setErrors({
                  ...errors,
                  [`${element}`]: error?.response?.data.error,
                });
              });
            }
          } else {
          }
        });
    } else {
      if (Object.keys(x).length === 1) {
        dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
      } else if (Object.keys(x).length >= 1) {
        dispatch(
          showInfoPopup(
            "error",
            lang === "en"
              ? "Please fill required fields with valid data"
              : "必須項目を適切に入力してください。 "
          )
        );
      }
    }
  };

  // useEffect ------------------------------------------------
  useEffect(() => {
    console.log(data);
  }, [data]);
  useEffect(() => {
    if (image) {
      setErrors((prev) => {
        delete prev.image;
        return { ...prev };
      });
    }
  }, [image]);

  useEffect(() => {
    dispatch(toggleShowDefaultHeader(false));
    dispatch(toggleShowDefaultFooter(false));
    if (token) {
      window.location = "/";
    } else {
      dispatch(toggleShowDefaultHeader(true));
      dispatch(toggleShowDefaultFooter(true));
    }
  }, [dispatch, token]);

  return (
    <>
      {!token &&
        (lang === "en" ? (
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL
          <div className="page_section_box">
            <PageTitle title="Sign Up & Post a JOB" />
            <Separator />
            <PageTitle
              sectionTitle="ACCOUNT INFO (FIRST USE)"
              text="Automatically create the company profile and all job information"
            />
            <div className="username_input_box">
              <span>Pick up your username</span>
              <Input
                handleKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                focusedIconName="add"
                defaultIconName="cal"
                placeholder="username"
                className="input_def username"
                pretext={<>pinjob.me/</>}
                onChange={(e) => {
                  if (isUsernameValid(e) || e.length === 0) {
                    handleChange("username", e);
                  }
                }}
                value={data?.username}
                error={errors?.username}
                customIcon={
                  <p className="username-counter">
                    {data?.username ? 16 - data?.username?.length : "16"}
                  </p>
                }
                maxLength={16}
              />
            </div>
            <div className="company_info_input_box">
              <div className="company_image_box">
                <ImageUpload
                  setImage={(res) => setImage(res)}
                  error={errors?.image}
                  imagePreview={imagePreview}
                  setImagePreview={(res) => setImagePreview(res)}
                  edit={true}
                />
              </div>
              <div className="company_info_inputs_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Name"
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Email"
                  onChange={(e) => handleChange("email", e)}
                  value={data?.email}
                  error={errors?.email}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Company Name"
                  onChange={(e) => handleChange("company", e)}
                  value={data?.company}
                  error={errors?.company}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="URL"
                  onChange={(e) => handleChange("url", e)}
                  value={data?.url}
                  error={errors?.url}
                  maxLength={50}
                />
                <Select
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  dropdownSelectClassName="country_inputs"
                  className=" "
                  label="Country"
                  options={countries}
                  flag={true}
                  selectedOption={data?.country_user}
                  setSelectedOption={(e) => {
                    handleChange("country_user", e);
                  }}
                  search={true}
                  clearIcon={true}
                  allOption={{ label: "All Countries", iconName: "flag" }}
                  error={errors?.country_user}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="City"
                  onChange={(e) => handleChange("city_user", e)}
                  value={data?.city_user}
                  error={errors?.city_user}
                  maxLength={50}
                />
              </div>
            </div>
            <Separator />
            <PostForm
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              countries={countries}
              data={data}
              errors={errors}
              handleChange={(res1, res2) => {
                handleChange(res1, res2);
              }}
              handleSubmit={() => handleSubmit()}
              setErrors={(res) => {
                setErrors(res);
              }}
              setData={(res) => {
                setData(res);
              }}
              desc={desc}
              setDesc={(res) => {
                setDesc(res);
                errors.job_description = false;
              }}
            />
          </div>
        ) : (
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN

          <div className="page_section_box">
            <PageTitle
              title="会員登録 & ファーストジョブの投稿"
              className={"jp_text"}
            />
            <Separator />
            <PageTitle
              sectionTitle="アカウント情報"
              text="会社のプロフィールとすべての求人情報を自動的に作成します。"
            />
            <div className="username_input_box">
              <span>ユーザーネーム:</span>
              <Input
                handleKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                focusedIconName="add"
                defaultIconName="cal"
                placeholder="username"
                className="input_def username"
                onChange={(e) => {
                  if (isUsernameValid(e) || e.length === 0) {
                    handleChange("username", e);
                  }
                }}
                value={data?.username}
                error={errors?.username}
                customIcon={
                  <p className="username-counter">
                    {data?.username ? 16 - data?.username?.length : "16"}
                  </p>
                }
                maxLength={16}
                pretext={<>pinjob.me/</>}
              />
            </div>
            <div className="company_info_input_box">
              <div className="company_image_box">
                <ImageUpload
                  setImage={(res) => setImage(res)}
                  error={errors?.image}
                  imagePreview={imagePreview}
                  setImagePreview={(res) => setImagePreview(res)}
                  edit={true}
                />
              </div>
              <div className="company_info_inputs_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="お名前"
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="メールアドレス"
                  onChange={(e) => handleChange("email", e)}
                  value={data?.email}
                  error={errors?.email}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="会社名"
                  onChange={(e) => handleChange("company", e)}
                  value={data?.company}
                  error={errors?.company}
                  maxLength={50}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="会社ウェブサイト"
                  onChange={(e) => handleChange("url", e)}
                  value={data?.url}
                  error={errors?.url}
                  maxLength={50}
                />

                <Select
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  dropdownSelectClassName="country_inputs"
                  className=" "
                  label="都道府県"
                  options={prefectures}
                  flag={true}
                  selectedOption={data?.prefecture_user}
                  setSelectedOption={(e) => handleChange("prefecture_user", e)}
                  search={true}
                  clearIcon={true}
                  allOption={{ label: "都道府県を選ぶ", iconName: "map" }}
                  error={errors?.prefecture_user}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="市区町村"
                  onChange={(e) => handleChange("city_user", e)}
                  value={data?.city_user}
                  error={errors?.city_user}
                  maxLength={50}
                />
              </div>
            </div>
            <Separator />
            <PostForm
              handleKeyDown={(e) => {
                handleKeyDown(e);
              }}
              countries={countries}
              data={data}
              errors={errors}
              handleChange={(res1, res2) => {
                handleChange(res1, res2);
              }}
              handleSubmit={() => handleSubmit()}
              setErrors={(res) => {
                setErrors(res);
              }}
              setData={(res) => {
                setData(res);
              }}
              desc={desc}
              setDesc={(res) => {
                console.log(res);
                setDesc(res);
                errors.job_description = false;
              }}
            />
          </div>
        ))}
    </>
  );
}

export default Signup;
