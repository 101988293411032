// NavigationLink.js
import React, { useEffect, useState } from "react";
import Input from "../compo/atoms/Input";
import { CreateDesigner, Signin } from "../services/Designer";
import { AdminLoginValidation } from "../validation";
import { useSelector, useDispatch } from "react-redux";
import {
  showInfoPopup,
  showSubmitPopup,
  setDesignerData,
  setUserRole,
} from "../redux/actions";
import Button from "../compo/atoms/Button";

function AdminLogin({}) {
  const [login, setLogin] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };

  const handleSubmit = () => {
    let x;
    // Assuming you have the necessary data to send in your request
    AdminLoginValidation(data, lang).then((errors) => {
      console.log(errors);
      setErrors(errors);
      x = errors;
      if (Object.keys(x).length === 0) {
        Signin(data?.login_email, "admin")
          .then((response) => {
            setData();
            if (response?.data?.message) {
              setData();
              dispatch(showSubmitPopup("success", "Link sent successfully"));
            }
          })
          .catch((error) => {
            dispatch(showInfoPopup("error", error?.response?.data?.message));
            setErrors({
              ...errors,
              login_email: error?.response?.data.error,
            });
          });
      } else {
        if (Object.keys(x).length === 1) {
          console.log([Object.keys(x)[0]]);
          dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
        } else if (Object.keys(x).length >= 1) {
          dispatch(
            showInfoPopup(
              "error",
              "Please fill required fields with valid data"
            )
          );
        }
      }
    });

    // Assuming you have the necessary data to send in your request
  };

  return (
    <>
      <div className="popup_lightbox_admin">
        <div className={`popup_box `}>
          <div className="popup_title_box">Admin Login</div>
          <div className="popup_input_box_admin">
            <Input
              focusedIconName="M"
              defaultIconName="mail"
              placeholder="Email"
              className="input_def"
              label=""
              onChange={(e) => handleChange("login_email", e)}
              value={data?.login_email}
              error={errors?.login_email}
            />
            <Button
              text="Send"
              icon1="send"
              icon2="send_ani"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
