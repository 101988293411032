import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../../compo/atoms/Button";
import Input from "../../compo/atoms/Input";
import Status from "../../compo/atoms/Status";
import Tags from "../../compo/atoms/Tag";
import CheckButton from "../../compo/atoms/CheckBtn";
import RadioButton from "../../compo/atoms/RadioBtn";
import Nav from "../../compo/atoms/Nav";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions";
import {
  showInfoPopup,
  showSubmitPopup,
  toggleShowUserLoginPopup,
  toggleShowCompanyLoginPopup,
} from "../../redux/actions";
import HeaderLogged from "./HeaderLogged";
import HeaderLoggedDesigner from "./HeaderLoggedDesigner";
export default function Header({
  text,
  className,
  showUserPopup,
  showCompanyPopup,
}) {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const designerData = useSelector((state) => state.designerData);
  const companyData = useSelector((state) => state.companyData);
  const token = useSelector((state) => state.token);
  const userRole = useSelector((state) => state.userRole);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1) {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "88px";
          el.style.borderBottom = "2px solid #00000010";
        });

        // document.querySelector(".header-mockup").style.display = "block";
      } else {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "120px";
          el.style.borderBottom = "1px solid transparent";
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {token ? (
        <>
          {userRole === "company" && <HeaderLogged />}
          {userRole === "designer" && <HeaderLoggedDesigner />}
        </>
      ) : (
        <>
          <div className="header_box">
            <div
              className="logo_box"
              onClick={() => {
                window.location = "/";
              }}
            >
              <CustomIcon iconName="logo_mark" className="logo_mark" />
              <CustomIcon iconName="logo_type" />
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL-  */}

            {lang === "en" ? (
              <>
                <div className="nav_link_box">
                  <Nav
                    name="JOBS"
                    link="/jobs"
                    icon="jobs"
                    hoverIcon="jobs_ani"
                  />
                  <Nav
                    name="COMPANIES"
                    link="/companies"
                    icon="company"
                    hoverIcon="company_ani"
                  />
                </div>

                <div
                  className={
                    "nav_link_box nav_link_box_right hide_box_on_mobile"
                  }
                >
                  <Nav
                    link="#"
                    icon="designer"
                    hoverIcon="designer_ani"
                    name="DESIGNERS"
                    className={"switch"}
                    onClick={() => {
                      dispatch(toggleShowUserLoginPopup());
                    }}
                  />
                  <Nav
                    name="EMPLOYERS"
                    link="#"
                    icon="employer"
                    hoverIcon="employer_ani"
                    className={"switch"}
                    onClick={() => {
                      dispatch(toggleShowCompanyLoginPopup());
                    }}
                  />
                </div>
                <div className="buttons_box, buttons_box_br1020 ">
                  <Button
                    text="A JOB"
                    text2="POST"
                    icon1="pinjob"
                    icon2="pinjob_ani"
                    className={"btn_def"}
                    onClick={() => {
                      window.location = "/signup";
                    }}
                  />
                  <Button
                    icon1="dots_on"
                    icon2="cart"
                    className={"btn_c_line btn_c_humb"}
                  />
                </div>
              </>
            ) : (
              // - - - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - JAPAN
              <>
                <div className="nav_link_box">
                  <Nav
                    name="ジョブ"
                    link="/jobs"
                    icon="jobs"
                    hoverIcon="jobs_ani"
                  />
                  <Nav
                    name="会社"
                    link="/companies"
                    icon="company"
                    hoverIcon="company_ani"
                  />
                </div>

                <div
                  className={
                    "nav_link_box nav_link_box_right hide_box_on_mobile"
                  }
                >
                  <Nav
                    link="#"
                    icon="designer"
                    hoverIcon="designer_ani"
                    name="デザイナー"
                    className={"switch"}
                    onClick={() => {
                      dispatch(toggleShowUserLoginPopup());
                    }}
                  />
                  <Nav
                    name="企業"
                    link=""
                    icon="employer"
                    hoverIcon="employer_ani"
                    className={"switch"}
                    onClick={() => {
                      dispatch(toggleShowCompanyLoginPopup());
                    }}
                  />
                </div>
                <div className="buttons_box, buttons_box_br1020 ">
                  <Button
                    text="を投稿"
                    text2="JOB"
                    icon1="pinjob"
                    icon2="pinjob_ani"
                    className={"btn_def"}
                    onClick={() => {
                      window.location = "/signup";
                    }}
                  />
                  <Button
                    icon1="dots_on"
                    icon2="cart"
                    className={"btn_c_line btn_c_humb"}
                  />
                </div>
              </>
            )}

            {/* <Button
          text={lang}
          className={"btn_def"}
          onClick={() => {
            dispatch(changeLanguage(lang == "en" ? "jp" : "en"));
          }}
        /> */}
          </div>
          <div className="header-mockup"></div>
        </>
      )}
    </>
  );
}
