// NavigationLink.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import Button from "../../compo/atoms/Button";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import PopupTab from "../atoms/PopupTab";
import { CreateDesigner, Signin } from "../../services/Designer";
import { UserLogin, UserSignup } from "../../validation";
import { showInfoPopup, showSubmitPopup } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const PopupCardCompany = ({ title, text, titleNumber, className, onClose }) => {
  const [login, setLogin] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of form submission
      handleSubmit();
    }
  };
  const handleSubmit = (close) => {
    // Assuming you have the necessary data to send in your request
    if (login) {
      setErrors(UserLogin(data));
      let x = UserLogin(data);
      // Assuming you have the necessary data to send in your request
      if (Object.keys(x).length === 0) {
        Signin(data?.login_email, "company")
          .then((response) => {
            if (response?.data?.message) {
              !close && onClose();
              close &&
                dispatch(showInfoPopup("success", "Email sent successfully"));
              setData();
              dispatch(
                showSubmitPopup(
                  lang === "en" ? "Login Link Sent!" : "ありがとうございます。",
                  lang === "en" ? (
                    <>
                      We've sent you an email with a link to access your
                      account. Please check your inbox!
                      <Button
                        text="登録する"
                        icon1="send"
                        icon2="send_ani"
                        className="btn_c_line_grey resend_link_btn"
                        onClick={() => {
                          handleSubmit(true);
                        }}
                      />
                    </>
                  ) : (
                    "アカウントにアクセスするためのリンクを含むメールをお送りしました。受信トレイをご確認ください！"
                  ),
                  "illust_01"
                )
              );
            }
          })
          .catch((error) => {
            dispatch(showInfoPopup("error", error?.response?.data?.error));
            setErrors({
              ...errors,
              login_email: error?.response?.data?.error,
            });
          });
      } else {
        if (Object.keys(x).length === 1) {
          dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
        } else if (Object.keys(x).length >= 1) {
          dispatch(
            showInfoPopup(
              "error",
              "Please fill required fields with valid data"
            )
          );
        }
      }
    }
  };
  return (
    <div className={`popup_lightbox ${className}`}>
      <div className="popup_box">
        <>
          <div className="popup_close_box">
            <Button
              icon1="close"
              icon2="close"
              className={"btn_c_icn"}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className="popup_title_box">
            FOR EMPLOYERS
            <CustomIcon iconName="company" />
          </div>
        </>

        <div className="popup_content_box">
          {
            <PopupTab
              tab1Name="Login"
              tab2Name="Signup"
              setLogin={(res) => {
                setLogin(res);
              }}
            />
          }
          {/* <CustomIcon iconName="illust_10" className="illus" /> */}
          {/* - - - - - - - - - - - - - - - -  SIGNUP - - - - - - - - - - - - - -  */}
          {!login && (
            <div className="form_box">
              <CustomIcon iconName="illust_10" className="illus" />

              <PageTitle
                sectionTitle="Employers Signup"
                text={
                  <>
                    Post your first job to sign up and create your account.
                    Remember to confirm your email address to activate your
                    account after signing up.
                    <br />
                    <strong>Welcome aboard!</strong>
                  </>
                }
              />
              <>
                {" "}
                <Button
                  text="POST YOUR FIRST JOB"
                  icon1="pinjob"
                  icon2="pinjob_ani"
                  onClick={() => {
                    window.location = "/signup";
                  }}
                />
                {/* <Button
                  text="PRICING"
                  icon1="money"
                  icon2="money_ani"
                  className="btn_c_grey"
                /> */}
              </>
            </div>
          )}

          {/* - - - - - - - - - - - - - - - -  LOGIN - - - - - - - - - - - - - -  */}
          <div className="form_box">
            {login && (
              <>
                {" "}
                <CustomIcon iconName="illust_07" className="illus" />
                <PageTitle
                  sectionTitle="Employers Login"
                  text="Receive your magic login link by email."
                />
              </>
            )}
            <div className="input_box_cont">
              {/*!login && (
                <Input
                          handleKeyDown={(e) => {
            handleKeyDown(e);
          }}
                  placeholder="Name"
                  className="input_def "
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                  defaultIconName={"mail"}
                />
              )*/}
              {login && (
                <>
                  <Input
                    handleKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                    focusedIconName="M"
                    defaultIconName="mail"
                    placeholder="Email"
                    className="input_def"
                    label=""
                    onChange={(e) => handleChange("login_email", e)}
                    value={data?.login_email}
                    error={errors?.login_email}
                  />

                  <Button
                    text="Send"
                    icon1="send"
                    icon2="send_ani"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        </div>
      </div>
    </div>
  );
};

export default PopupCardCompany;
