import axios from "axios";
import { BACKEND_URL } from "../config";

export const CreatePost = async (data, desc, lang) => {
  const requestData =
    data?.remote?.value === "remote" || data?.remote?.value === "リモート"
      ? {
          username: data.username,
          title: data.jobTitle.value,
          type: data.jobType.value,
          endpoint: data.endpoint,
          remote: data.remote.value,
          currency: "usd",
          minimum_salary: data.minimum_salary,
          maximum_salary: data.maximum_salary,
          job_description: desc,
          lang: lang,
        }
      : lang === "en"
      ? {
          username: data.username,
          title: data.jobTitle.value,
          type: data.jobType.value,
          country: data.country.search,
          city: data.city,
          endpoint: data.endpoint,
          remote: data.remote.value,
          currency: "usd",
          minimum_salary: data.minimum_salary,
          maximum_salary: data.maximum_salary,
          job_description: desc,
          lang: lang,
        }
      : {
          username: data.username,
          title: data.jobTitle.value,
          type: data.jobType.value,
          prefecture: data.prefecture.search,
          city: data.city,
          endpoint: data.endpoint,
          remote: data.remote.value,
          currency: "usd",
          minimum_salary: data.minimum_salary,
          maximum_salary: data.maximum_salary,
          job_description: desc,
          lang: lang,
        };
  return await axios.post(`${BACKEND_URL}/api/post/create`, requestData, {
    headers: {
      "Content-Type": "application/json", // Ensure correct content type
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
    },
  });
};

export const incrementClicks = async (postId) => {
  return await axios.put(
    `${BACKEND_URL}/api/post/click/${postId}`,
    {},
    localStorage.getItem("token") && {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    }
  );
};

export const incrementViews = async (postId) => {
  return await axios.put(
    `${BACKEND_URL}/api/post/view/${postId}`,
    {},
    localStorage.getItem("token") && {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    }
  );
};

export const updatePost = async (postId, data) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/post/update/${postId}`, // Your API endpoint
    data,
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    }
  );
  return response.data;
};
export const renewPost = async (postId) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/post/renew/${postId}`, // Your API endpoint
    {},
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    }
  );
  return response.data;
};
export const approvePost = async (postId) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/post/approve/${postId}`, // Your API endpoint
    {},
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
      },
    }
  );
  return response.data;
};

export const stopPost = async (postId) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/post/stop/${postId}`, // Your API endpoint
    {},
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
      },
    }
  );
  return response.data;
};

export const deletePost = async (postId, admin) => {
  const response = await axios.delete(
    `${BACKEND_URL}/api/post/delete/${postId}`, // Your API endpoint
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: admin
          ? `Bearer ${localStorage.getItem("admin_token")}`
          : `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    },
    {}
  );
  return response.data;
};
