import "../styles/main.scss";
import { AdminListSearch } from "../compo/templates/ListSearch";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BACKEND_URL } from "../config";
import {
  setCompanyData,
  setDesignerData,
  toggleShowDefaultFooter,
} from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { setUserRole } from "../redux/actions";
import { getDesignerDataRequest } from "../services/Designer";

import { toggleShowDefaultHeader } from "../redux/actions";
import HeaderAdmin from "../compo/items/HeaderAdmin";
import { getCompanyDataRequest } from "../services/Company";

function Admin() {
  // declarations ------------------------------

  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.userRole);
  const lang = useSelector((state) => state.lang);
  const designerData = useSelector((state) => state.designerData);
  const [filter, setFilter] = useState({});
  const [adminTab, setAdminTab] = useState("job");
  const [loading, setLoading] = useState("job");
  const { password, username } = useParams();
  const [companiesData, setComapniesData] = useState();
  const [postsData, setPostsData] = useState();
  const [JobsNext, setJobsNext] = useState(`${BACKEND_URL}/api/posts`);
  const [CompaniesNext, setCompaniesNext] = useState(
    `${BACKEND_URL}/api/companies`
  );

  const [isAdminUser, setIsAdminUser] = useState(false);
  const [postsJobsNo, setPostsJobsNo] = useState();
  const [companiesJobsNo, setCompaniesJobsNo] = useState();

  // functions -----------------------------

  const handleFunction = async () => {
    const pageIndex = Math.ceil(postsData.length / 20);
    let link = `${BACKEND_URL}/api/posts`;
    let dataStore = [];
    for (let i = 0; i < pageIndex; i++) {
      try {
        const response = await axios.get(link, {
          params: {
            type: filter?.jobType?.value,
            title: filter?.jobTitle?.value,
            country: filter?.country?.search,
            search: filter?.search,
            salary: filter?.salary,
            remote: [filter?.remote, filter?.hybrid, filter?.on_site],
            currency: filter?.currency?.search,
            lang: lang,
            admin: filter?.pendingJobs,
            page: i + 1,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
          },
        });

        if (response.data.results) {
          dataStore.push(...response.data.results);
          setPostsJobsNo(response.data.count);
        }
      } catch (error) {
        console.error(error); // handle errors here
      }
    }
    setPostsData(dataStore);
  };

  const handlePagination = (link) => {
    console.log(adminTab, link);
    if (adminTab === "job") {
      axios
        .get(link, {
          params: {
            type: filter?.jobType?.value,
            title: filter?.jobTitle?.value,
            country: filter?.country?.search,
            search: filter?.search,
            salary: filter?.salary,
            remote: [filter?.remote, filter?.hybrid, filter?.on_site],
            currency: filter?.currency?.search,
            lang: lang,
            admin: filter?.pendingJobs,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
          },
        })
        .then((response) => {
          if (response.data.results) {
            setPostsData((prev) => {
              const prevIds = prev.map((el) => {
                return el.id;
              });
              const newIds = response.data.results.map((el) => {
                return el.id;
              });

              if (!prevIds?.includes(newIds[0]) && link?.includes("page")) {
                prev.push(...response.data.results);
                setJobsNext(response.data.next);

                setPostsJobsNo(response.data.count);
              } else if (link === JobsNext && !link.includes("page")) {
                prev = response.data.results;
                setJobsNext(response.data.next);
                setPostsJobsNo(response.data.count);
              } else if (link !== JobsNext) {
                response.data.results.forEach((el) => {
                  let index = prev.findIndex((obj) => obj.id === el.id);

                  prev[index] = el;
                });
              }
              return [...prev];
            });
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
        });
    } else if (adminTab === "company") {
      axios
        .get(link, {
          params: {
            search: filter.search,
            country: filter?.country?.search,
            prefecture: filter?.prefecture?.search,
            lang: lang,
          },
        })
        .then((response) => {
          if (response.data.results) {
            setComapniesData((prev) => {
              const prevIds = prev.map((el) => {
                return el.user.id;
              });
              const newIds = response.data.results.map((el) => {
                return el.user.id;
              });

              if (!prevIds.includes(newIds[0])) {
                prev.push(...response.data.results);
                setCompaniesNext(response.data.next);
                setCompaniesJobsNo(response.data.count);
              }

              return [...prev];
            });
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
        });
    }
  };

  // useEffect Hook -------------------------------

  useEffect(() => {
    if (password) {
      axios
        .post(
          `${BACKEND_URL}/api/admin/login`,
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure correct content type
            },
          }
        )
        .then((res) => {
          return res.data;
          //   console.log(jwtDecode(response.data.access));
        })
        .then((data) => {
          localStorage.setItem("admin_token", data.access);
          setIsAdminUser(true);

          if (data.type === "designer") {
            dispatch(setCompanyData(false));
            dispatch(setUserRole("designer"));
            localStorage.setItem("user_role", "designer");
            getDesignerDataRequest(
              jwtDecode(localStorage.getItem("admin_token"))?.user_id
            )
              .then((res) => {
                return res.data;
              })
              .then((data) => {
                dispatch(setDesignerData(data));
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (data.type === "company") {
            dispatch(setDesignerData(false));
            dispatch(setUserRole("company"));
            localStorage.setItem("user_role", "company");
            getCompanyDataRequest(true)
              .then((res) => {
                dispatch(setCompanyData(res.results[0]));
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error?.response?.data?.error);
          window.location = "/soon";
        });
    }
  }, [dispatch, username, password]);

  useEffect(() => {
    const search = (filterData) => {
      axios
        .get(`${BACKEND_URL}/api/posts`, {
          params: {
            type: filterData?.jobType?.value,
            title: filterData?.jobTitle?.value,
            country: filterData?.country?.search,
            search: filterData?.search,
            salary: filterData?.salary,
            remote: [
              filterData?.remote,
              filterData?.hybrid,
              filterData?.on_site,
            ],
            currency: filterData?.currency?.value,
            prefecture: filterData?.prefecture?.search,
            lang: lang,
            admin: filterData?.pendingJobs ? true : "",
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
          },
        })
        .then((response) => {
          if (response.data) {
            setPostsData((prev) => {
              prev = response.data.results;
              setJobsNext(response.data.next);
              setPostsJobsNo(response.data.count);

              return [...prev];
            });
            localStorage.setItem(
              "AdminAdminjobPosts",
              JSON.stringify([response.data.count, ...response.data.results])
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
          setLoading(false);
        });
      axios
        .get(`${BACKEND_URL}/api/companies`, {
          params: {
            search: filter.search,
            country: filter?.country?.search,
            prefecture: filter?.prefecture?.search,
            lang: lang,
          },
          headers: {
            "Content-Type": "application/json", // Ensure correct content type
            Authorization: `Bearer ${localStorage.getItem("admin_token")}`, // Assuming you have a JWT token
          },
        })
        .then((response) => {
          if (response.data) {
            localStorage.setItem(
              "companies",
              JSON.stringify([response.data.count, ...response.data.results])
            );
            setComapniesData(response.data.results);
            setCompaniesNext(response.data.next);
            setCompaniesJobsNo(response.data.count);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          // handle errors here
        });
    };
    const debounceTimeout = setTimeout(() => {
      if (filter) {
        search(filter);
      }
    }, 300); // Adjust the delay as needed (e.g., 1000 milliseconds = 1 second)
    return () => clearTimeout(debounceTimeout);
  }, [filter, userRole, lang]);

  useEffect(() => {
    dispatch(toggleShowDefaultHeader(false));
    dispatch(toggleShowDefaultFooter(false));
  }, [isAdminUser, dispatch]);

  return (
    <>
      {isAdminUser && (
        <>
          <HeaderAdmin />

          <AdminListSearch
            data={adminTab === "job" ? postsData : companiesData}
            filter={filter}
            setFilter={(val) => setFilter(val)}
            jobsNo={adminTab === "job" ? postsJobsNo : companiesJobsNo}
            designer={designerData}
            page={"admin"}
            adminTab={adminTab}
            setAdminTab={(res) => {
              setAdminTab(res);
            }}
            handlePagination={(res) => {
              handlePagination(res);
            }}
            handleFunction={(res) => {
              handleFunction(res);
            }}
            next={adminTab === "job" ? JobsNext : CompaniesNext}
            pagination={true}
            loading={loading}
          />
        </>
      )}
    </>
  );
}

export default Admin;
