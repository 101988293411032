import axios from "axios";
import { BACKEND_URL } from "../config";

export const deleteUser = async (userId, admin) => {
  const response = await axios.delete(
    `${BACKEND_URL}/api/user/delete/${userId}`, // Your API endpoint
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
        Authorization: admin
          ? `Bearer ${localStorage.getItem("admin_token")}`
          : `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
      },
    },
    {}
  );
  return response.data;
};
export const isAdmin = async (username) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/user/isadmin/${username}`
  );
  return response.data;
};
export const getUserState = async (userId) => {
  const response = await axios.get(`${BACKEND_URL}/api/user/state/${userId}`);
  return response.data;
};
