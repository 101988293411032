import "../../styles/items.scss";
import "../../styles/atoms.scss";
import CustomIcon from "../../data/IconCompo";
import { useDispatch, useSelector } from "react-redux";

export default function TopPageIntro({ className }) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className="top_intro_box">
      <div className="top_intro_content_box">
        {lang === "en" ? (
          <div className="top_intro_left">
            <h8>DESIGNERS</h8>
            <h8>ONLY</h8>
            <span>JOB BOARD</span>
          </div>
        ) : (
          <div className="top_intro_left jp">
            <h8>デザイナー</h8>
            <h8>特化した</h8>
            <span>ジョブボート</span>
          </div>
        )}
        <div className="top_intro_right">
          <CustomIcon iconName="illust_11" className="illus" />
        </div>
      </div>
    </div>
  );
}
