import Header from "../compo/items/Header";
import "../styles/main.scss";
import ListSearch from "../compo/templates/ListSearch";
import TopPageIntro from "../compo/items/TopPageIntro";
import PageTitle from "../compo/atoms/PageTitle";
import Input from "../compo/atoms/Input";
import ImageUpload from "../compo/items/ImageUpload";
import TextArea from "../compo/atoms/TextArea";
import Button from "../compo/atoms/Button";
import Select from "../compo/atoms/Select";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import PostForm from "../compo/templates/PostForm";
import countries from "../data/countires.json";
import Separator from "../compo/atoms/Separator";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_URL } from "../config";
import { useParams } from "react-router-dom";
import prefectures from "../data/prefectures.json";
import { showInfoPopup, showSubmitPopup } from "../redux/actions";
import CustomIcon from "../data/IconCompo";
import { deleteUser } from "../services/User";

function CompanyUpdate({}) {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [email, setEmail] = useState();
  const [cancelConfirm, setCancelConfirm] = useState(true);
  const [firstData, setFirstData] = useState({});
  const [firstImage, setFirstImage] = useState({});
  const [imagePreview, setImagePreview] = useState();
  const { password } = useParams();
  const lang = useSelector((state) => state.lang);
  const companyData = useSelector((state) => state.companyData);

  const dispatch = useDispatch();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const openDeletePopup = () => {
    dispatch(
      showSubmitPopup(
        lang === "en" ? "Delete My Account" : "ありがとうございます。",
        lang === "en" ? (
          <div className="user-delete-cont-popup">
            <p>
              Once you delete your account, all your posts, data cannot be
              recovered, and any time remaining for posting will be lost without
              the option for a refund.
            </p>
            <Button
              text="Don't Delete my account"
              icon1="back"
              className=""
              onClick={() => {
                dispatch(showSubmitPopup(false));
              }}
            />
            <Button
              text="Delete my account"
              icon1="trash"
              className="user-delete-button"
              onClick={() => {
                handleDelete();
              }}
            />
          </div>
        ) : (
          <div className="user-delete-cont-popup">
            <p>
              Once you delete your account, all your posts, data cannot be
              recovered, and any time remaining for posting will be lost without
              the option for a refund.
            </p>
            <Button
              text="Don't Delete my account"
              icon1="back"
              className=""
              onClick={() => {
                dispatch(showSubmitPopup(false));
              }}
            />
            <Button
              text="Delete my account"
              icon1="trash"
              className="user-delete-button"
              onClick={() => {
                handleDelete();
              }}
            />
          </div>
        ),
        "illust_12",
        () => {}
      )
    );
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of form submission
      handleSubmit();
    }
  };
  const handleDelete = () => {
    deleteUser(companyData?.user?.id)
      .then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("designerData");
        localStorage.removeItem("companyData");
        localStorage.removeItem("myJobs");
        localStorage.removeItem("pins");
        dispatch(showSubmitPopup(false));
        dispatch(
          showInfoPopup(
            "success",
            "Account deleted successfully",
            4000,
            "ok",
            () => {
              window.location = "/";
            }
          )
        );
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", "Please try again later"));
        console.log(err);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("user_role") !== "company") {
      window.location = "/404";
    }
  }, []);
  useEffect(() => {
    if (password) {
      if (!localStorage.getItem("token")) {
        dispatch(
          showInfoPopup(
            "error",
            "You have to login first and then click the link",
            4000,
            {},
            () => {
              window.location = "/";
            }
          )
        );
      }
      const requestData = new FormData();
      requestData.append("password", password);

      axios
        .post(
          `${BACKEND_URL}/api/email/confirm`,
          requestData, // Pass FormData directly here
          {
            headers: {
              "Content-Type": "multipart/form-data", // Use multipart/form-data for FormData
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
            },
          }
        )
        .then((response) => {
          dispatch(
            showSubmitPopup(
              lang === "en" ? "DONE" : "ありがとうございます。",
              lang === "en"
                ? "Your profile information has been successfully updated."
                : "ご協力をありがとうございます。投稿が正常に完了しました。",
              "",
              () => {
                window.location = `http://localhost:3000/company/update`;
              }
            )
          );
        })
        .catch((error) => {
          window.location = "/company/update";

          if (error?.response?.data.error) {
            dispatch(showInfoPopup("error", error?.response?.data.error));
            let errorFields = error?.response?.data?.fields;
            if (errorFields) {
              errorFields.forEach((element) => {
                setErrors({
                  ...errors,
                  [`${element}`]: error?.response?.data.error,
                });
              });
            }
          }
        });
    }
  }, []);
  useEffect(() => {
    if (companyData) {
      setData(
        lang === "en"
          ? {
              ...companyData,
              company: companyData?.company_name,
              url: companyData?.url,
              id: companyData?.user.id,
              email: companyData?.user.email,
              username: companyData?.user.username,
              country: findItemByKey(
                countries,
                "label",
                capitalizeFirstLetter(companyData?.country)
              ),
              city: companyData?.city,
            }
          : {
              ...companyData,
              company: companyData?.company_name,
              url: companyData?.url,
              id: companyData?.user.id,
              email: companyData?.user.email,
              username: companyData?.user.username,
              prefecture: findItemByKey(
                prefectures,
                "label",
                companyData?.prefecture
              ),
              city: companyData?.city,
            }
      );
      setFirstData(
        lang === "en"
          ? {
              ...companyData,
              company: companyData?.company_name,
              url: companyData?.url,
              id: companyData?.user.id,
              email: companyData?.user.email,
              username: companyData?.user.username,
              country: findItemByKey(
                countries,
                "label",
                capitalizeFirstLetter(companyData?.country)
              ),
              city: companyData?.city,
            }
          : {
              ...companyData,
              company: companyData?.company_name,
              url: companyData?.url,
              id: companyData?.user.id,
              email: companyData?.user.email,
              username: companyData?.user.username,
              prefecture: findItemByKey(
                prefectures,
                "label",
                companyData?.prefecture
              ),
              city: companyData?.city,
            }
      );
      setConfirmEmail(companyData?.user?.confirm_email);
      setEmail(companyData?.user?.email);
      setFirstImage(`${BACKEND_URL}/${companyData?.image}`);
      setImagePreview(`${BACKEND_URL}/${companyData?.image}`);
    }
  }, [companyData, lang]);

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  function findItemByKey(array, key, value) {
    if (value) {
      return array.find(
        (item) => item[key].toLowerCase() === value.toLowerCase()
      );
    } else {
      return "";
    }
  }
  function validateEndpoint(value) {
    // Regular expression to validate URL
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the value is a valid URL or email
    return urlRegex.test(value) || emailRegex.test(value);
  }
  function validateForm(formData) {
    const error = {};

    // Check if all fields are present
    const requiredFields = [
      "name",
      "username",
      "email",
      "url",
      "image",
      "company",
      "city",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        error[field] = `${field} field is required`;
      }
    });
    // Additional validation for specific fields (if needed)
    if (formData["email"] && !validateEmail(formData["email"])) {
      error["email"] = `Invalid email format`;
    }
    if (image?.size > 1000000) {
      error.image =
        lang === "en"
          ? "Image size is to big please upload another image"
          : `画像のサイズが大きすぎますので、別の画像をアップロードしてください`;
    }
    if (!validateEndpoint(formData.url)) {
      error.url = "Endpoint must be an endpoint or url";
    }
    if (lang == "en") {
      if (!formData?.country?.search) {
        error.country =
          lang === "en"
            ? "Country field is required"
            : `都道府県を選択してください。`;
      }
    } else {
      if (!formData?.prefecture?.search) {
        error.prefecture =
          lang === "en"
            ? "prefecture field is required"
            : `都道府県を選択してください。`;
      }
    }
    return error;
  }
  function validateEmail(email) {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(validateForm(data));
    let x = validateForm(data);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      dispatch(
        showInfoPopup("loading", "Connecting!...", 4000, "right_arrow_ani")
      );
      const requestData = new FormData();
      if (data.company !== firstData.company) {
        requestData.append("company_name", data.company);
      }
      if (data.url !== firstData.url) {
        requestData.append("url", data.url);
      }
      if (data.name !== firstData.name) {
        requestData.append("name", data.name);
      }
      if (data.email !== firstData.email) {
        requestData.append("confirm_email", data.email);
      }
      if (data.username !== firstData.username) {
        requestData.append("username", data.username.toLowerCase());
      }
      if (lang === "en") {
        requestData.append("country", data?.country?.label);
      } else {
        requestData.append("prefecture", data?.prefecture?.label);
      }
      requestData.append("city", data.city);

      if (image) {
        requestData.append("image", image);
      }

      axios
        .put(
          `${BACKEND_URL}/api/company/update`,
          requestData, // Pass FormData directly here
          {
            headers: {
              "Content-Type": "multipart/form-data", // Use multipart/form-data for FormData
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
            },
          }
        )
        .then((response) => {
          dispatch(showInfoPopup(false));

          dispatch(
            showInfoPopup(
              "success",
              "user changed successfully",
              4000,
              "right_arrow_ani",
              () => {
                window.location.reload();
              }
            )
          );
        })
        .catch((error) => {
          if (error?.response?.data.error) {
            dispatch(showInfoPopup("error", error?.response?.data.error));

            let errorFields = error?.response?.data?.fields;
            if (errorFields) {
              errorFields.forEach((element) => {
                setErrors({
                  ...errors,
                  [`${element}`]: error?.response?.data.error,
                });
              });
            }
          }
        });
    } else {
      if (Object.keys(x).length === 1) {
        dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
      } else if (Object.keys(x).length >= 1) {
        dispatch(
          showInfoPopup("error", "Please fill required fields with valid data")
        );
      }
    }
  };
  const handleCancelEmail = () => {
    const requestData = new FormData();
    requestData.append("confirm_email", email);
    dispatch(
      showInfoPopup("loading", "Connecting!...", 4000, "right_arrow_ani")
    );
    axios
      .put(
        `${BACKEND_URL}/api/company/update`,
        requestData, // Pass FormData directly here
        {
          headers: {
            "Content-Type": "multipart/form-data", // Use multipart/form-data for FormData
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
          },
        }
      )
      .then((response) => {
        dispatch(showInfoPopup(false));

        dispatch(
          showInfoPopup(
            "success",
            "user changed successfully",
            4000,
            "right_arrow_ani",
            () => {
              window.location.reload();
            }
          )
        );
      })
      .catch((error) => {
        if (error?.response?.data.error) {
          dispatch(showInfoPopup("error", error?.response?.data.error));
          let errorFields = error?.response?.data?.fields;
          if (errorFields) {
            errorFields.forEach((element) => {
              setErrors({
                ...errors,
                [`${element}`]: error?.response?.data.error,
              });
            });
          }
        }
      });
  };
  useEffect(() => {
    if (image) {
      setErrors((prev) => {
        delete prev.image;
        return { ...prev };
      });
    }
  }, [image]);
  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };
  return (
    <>
      {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL-  */}
      {localStorage.getItem("user_role") === "company" &&
        (lang === "en" ? (
          <div className="page_section_box_short">
            <PageTitle title="My Account" />
            <Separator />

            <div className="company_info_input_box">
              <div className="company_image_box">
                <ImageUpload
                  setImage={(res) => setImage(res)}
                  error={errors?.image}
                  imagePreview={imagePreview}
                  setImagePreview={(res) => setImagePreview(res)}
                  edit={true}
                />
              </div>
              <div className="company_info_inputs_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  label="Username"
                  focusedIconName="add"
                  defaultIconName="cal"
                  placeholder="username"
                  className="input_def username"
                  onChange={(e) => {
                    if (isUsernameValid(e) || e.length === 0) {
                      handleChange("username", e);
                    }
                  }}
                  value={data?.username}
                  error={errors?.username}
                  customIcon={
                    <p className="username-counter">{data?.username?.length}</p>
                  }
                  maxLength={16}
                  pretext={<>pinjob.me/</>}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Name"
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                />

                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Company Name"
                  onChange={(e) => handleChange("company", e)}
                  value={data?.company}
                  error={errors?.company}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="URL"
                  onChange={(e) => handleChange("url", e)}
                  value={data?.url}
                  error={errors?.url}
                />
                <Select
                  placeholder=""
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  className=" signup_username_input"
                  label="Country"
                  options={countries}
                  flag={true}
                  selectedOption={data?.country}
                  error={errors?.country}
                  setSelectedOption={(e) => handleChange("country", e)}
                  search={true}
                  clearIcon={true}
                  allOption={{ label: "All prefecture", iconName: "company" }}
                />

                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="City"
                  onChange={(e) => handleChange("city", e)}
                  value={data?.city}
                  error={errors?.city}
                />
                <div className="user_update_email_cont">
                  <Input
                    handleKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                    placeholder=""
                    className="input_def signup_username_input"
                    label="Email"
                    onChange={(e) => handleChange("email", e)}
                    value={data?.email}
                    error={errors?.email}
                  />

                  {data && email !== confirmEmail && cancelConfirm && (
                    <div className="user_update_email">
                      <div className="user_update_email_message">
                        <p>please verify email</p>
                        <p>
                          <span>{confirmEmail}</span>
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          handleCancelEmail();
                          setCancelConfirm(false);
                        }}
                      >
                        <CustomIcon
                          iconName={"close"}
                          className="user_update_email_icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="signup_save_button_cont">
                  <Button
                    text="Save"
                    icon1="ok"
                    icon2="ok_ani"
                    className="profile_save_button"
                    onClick={() => {
                      if (
                        !(
                          JSON.stringify(firstData) === JSON.stringify(data) &&
                          imagePreview === firstImage
                        )
                      ) {
                        handleSubmit();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="user-delete-cont">
              <p className="user-delete-text">Delete my account definitely!</p>
              <Button
                text="Delete my account"
                icon1="trash"
                className="user-delete-button "
                onClick={() => {
                  openDeletePopup();
                }}
              />
            </div>
          </div>
        ) : (
          //* - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN-  */}

          <div className="page_section_box_short">
            <PageTitle title="マイアカウント" className={"jp_text"} />
            <Separator />

            <div className="company_info_input_box">
              <div className="company_image_box">
                <ImageUpload
                  setImage={(res) => setImage(res)}
                  error={errors?.image}
                  imagePreview={imagePreview}
                  setImagePreview={(res) => setImagePreview(res)}
                  edit={true}
                />
              </div>
              <div className="company_info_inputs_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  label="ユーザーネーム"
                  focusedIconName="add"
                  defaultIconName="cal"
                  placeholder="username"
                  className="input_def username"
                  onChange={(e) => {
                    if (isUsernameValid(e) || e.length === 0) {
                      handleChange("username", e);
                    }
                  }}
                  value={data?.username}
                  error={errors?.username}
                  customIcon={
                    <p className="username-counter">{data?.username?.length}</p>
                  }
                  maxLength={16}
                  pretext={<>pinjob.me/</>}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="お名前"
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                />

                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="社名"
                  onChange={(e) => handleChange("company", e)}
                  value={data?.company}
                  error={errors?.company}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="会社ウェブサイト"
                  onChange={(e) => handleChange("url", e)}
                  value={data?.url}
                  error={errors?.url}
                />
                <Select
                  placeholder="All"
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  className=" "
                  label="都道府県"
                  options={prefectures}
                  flag={true}
                  selectedOption={data?.prefecture}
                  error={errors?.prefecture}
                  setSelectedOption={(e) => handleChange("prefecture", e)}
                  search={true}
                  clearIcon={true}
                  allOption={{ label: "都道府県を選ぶ", iconName: "map" }}
                  searchList={prefectures}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  placeholder=""
                  className="input_def signup_username_input"
                  label="市区町村"
                  onChange={(e) => handleChange("city", e)}
                  value={data?.city}
                  error={errors?.city}
                />
                <div className="user_update_email_cont">
                  <Input
                    handleKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                    placeholder=""
                    className="input_def signup_username_input"
                    label="メールアドレス"
                    onChange={(e) => handleChange("email", e)}
                    value={data?.email}
                    error={errors?.email}
                  />

                  {data && email !== confirmEmail && cancelConfirm && (
                    <div className="user_update_email">
                      <div className="user_update_email_message">
                        <p>please verify email</p>
                        <p>
                          <span>{confirmEmail}</span>
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          handleCancelEmail();
                          setCancelConfirm(false);
                        }}
                      >
                        <CustomIcon
                          iconName={"close"}
                          className="user_update_email_icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="signup_save_button_cont">
                  <Button
                    text="保存"
                    icon1="ok"
                    icon2="ok_ani"
                    className="profile_save_button"
                    onClick={() => {
                      if (
                        !(
                          JSON.stringify(firstData) === JSON.stringify(data) &&
                          imagePreview === firstImage
                        )
                      ) {
                        handleSubmit();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="user-delete-cont">
              <p className="user-delete-text">Delete my account definitely!</p>
              <Button
                text="Delete my account"
                icon1="trash"
                className="user-delete-button "
                onClick={() => {
                  openDeletePopup();
                }}
              />
            </div>
          </div>
        ))}
    </>
  );
}

export default CompanyUpdate;
