import React, { useState, cloneElement, useEffect } from "react";
import Footer from "../items/Footer";
import Header from "../items/Header";
import PopupTemp from "./PopupTemp";
import InfoPopup from "../atoms/InfoPopup";
import HeaderLogged from "../items/HeaderLogged";
import HeaderLoggedDesigner from "../items/HeaderLoggedDesigner";

import axios from "axios";
import PopupCardCompany from "../items/PopupCardCompany";
import PopupCardUser from "../items/PopupCardUser";
import { jwtDecode } from "jwt-decode";
import { BACKEND_URL } from "../../config";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HeaderSimple from "../items/HeaderSimple";
import FooterSimple from "../items/FooterSimple";
import { useSelector, useDispatch } from "react-redux";
import {
  showInfoPopup,
  showSubmitPopup,
  toggleShowUserLoginPopup,
  toggleShowCompanyLoginPopup,
  setDesignerData,
  setCompanyData,
  setUserRole,
  setToken,
  changeLanguage,
  toggleShowDefaultHeader,
} from "../../redux/actions";
import CustomIcon from "../../data/IconCompo";

const Layout = ({ children }) => {
  const infoPopupData = useSelector((state) => state.infoPopupData);
  const token = useSelector((state) => state.token);
  const submitPopupData = useSelector((state) => state.submitPopupData);
  const userRole = useSelector((state) => state.userRole);
  const showUserLoginPopup = useSelector((state) => state.showUserLoginPopup);
  const ShowDefaultHeader = useSelector((state) => state.ShowDefaultHeader);
  const ShowDefaultFooter = useSelector((state) => state.ShowDefaultFooter);
  const companyData = useSelector((state) => state.companyData);
  const designerData = useSelector((state) => state.designerData);
  const location = useLocation();
  const path = location.pathname.substring(1);
  const dispatch = useDispatch();
  const showCompanyLoginPopup = useSelector(
    (state) => state.showCompanyLoginPopup
  );

  const [imgSrc, setImgSrc] = useState(
    localStorage.getItem("user_role") == "company" &&
      JSON.parse(localStorage.getItem("companyData"))
      ? JSON.parse(localStorage.getItem("companyData")).image
      : localStorage.getItem("user_role") == "designer" &&
        JSON.parse(localStorage.getItem("designerData"))
      ? JSON.parse(localStorage.getItem("designerData")).image
      : null
  );
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setTimeout(() => {
      document.querySelector("#root").style.display = "block";
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(setToken(localStorage.getItem("token")));

    dispatch(
      setCompanyData(
        localStorage.getItem("companyData")
          ? JSON.parse(localStorage.getItem("companyData"))
          : ""
      )
    );
    dispatch(
      setDesignerData(
        localStorage.getItem("designerData") && localStorage.getItem("pins")
          ? {
              ...JSON.parse(localStorage.getItem("designerData")),
              pin: JSON.parse(localStorage.getItem("pins"))
                .slice(1)
                .map((el) => {
                  return el.id;
                }),
            }
          : ""
      )
    );
  }, []);

  useEffect(() => {
    let infoPopup = document.querySelector(".info-popup");
    let timerId; // Declare timerId variable outside of the setTimeout function
    let timerId1; // Declare timerId variable outside of the setTimeout function

    if (infoPopupData && infoPopup) {
      // infoPopup.style.left = `calc( 50% - ${infoPopup.clientWidth / 2}px)`;
      setTimeout(() => {
        infoPopup.style.top = "32px";
      }, 100);

      // Cancel the existing timer if it exists
      clearTimeout(timerId);
      clearTimeout(timerId1);

      // Start a new timer
      timerId = setTimeout(() => {
        infoPopup.style.top = "-100px";
        timerId1 = setTimeout(() => {
          dispatch(showInfoPopup(false));
          infoPopupData.onFinish();
        }, 500);
      }, infoPopupData.time);
    }

    // Cleanup function to cancel the timer when the component unmounts or when infoPopupData changes
    return () => {
      try {
        infoPopupData.onFinish();
      } catch {
        console.log("error");
      }
      clearTimeout(timerId1);
      clearTimeout(timerId);
    };
  }, [infoPopupData, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("user_role") === "company") {
        dispatch(setUserRole("company"));
        axios
          .get(`${BACKEND_URL}/api/companies`, {
            params: {
              me: true,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.data) {
              localStorage.setItem(
                "companyData",
                JSON.stringify({
                  ...response.data.results[0],
                  image: response.data.results[0].image,
                })
              );
              if (
                JSON.stringify(companyData) !==
                JSON.stringify(response.data.results[0])
              ) {
                dispatch(setCompanyData(response.data.results[0]));
                setImgSrc(response.data.results[0].image);
              }
            }
          })
          .catch((error) => {
            dispatch(setCompanyData(false));
            localStorage.removeItem("token");
            localStorage.removeItem("user_role");
            localStorage.removeItem("designerData");
            localStorage.removeItem("companyData");
          });
      } else if (localStorage.getItem("user_role") === "designer") {
        dispatch(setUserRole("designer"));
        axios
          .get(
            `${BACKEND_URL}/api/designer/${
              jwtDecode(localStorage.getItem("token"))?.user_id
            }`,
            {
              headers: {
                "Content-Type": "application/json", // Ensure correct content type
                Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
              },
            }
          )
          .then((response) => {
            if (response.data) {
              localStorage.setItem(
                "designerData",
                JSON.stringify({
                  ...response.data,
                  image: response.data.image,
                })
              );
              if (
                JSON.stringify(designerData) !== JSON.stringify(response.data)
              ) {
                dispatch(setDesignerData(response.data));
                setImgSrc(response.data.image);
              }

              localStorage.setItem("user_role", "designer");
            }
          })
          .catch((error) => {
            dispatch(setDesignerData(false));
            localStorage.removeItem("token");
            localStorage.removeItem("user_role");
            localStorage.removeItem("designerData");
            localStorage.removeItem("companyData");

            console.error(error); // handle errors here
          });
      }
    }
  }, []);

  return (
    <>
      <div className="app">
        {ShowDefaultHeader && <Header />}
        {children}
        {ShowDefaultFooter && <Footer />}
      </div>

      {submitPopupData && (
        <div className="popup-cont">
          <PopupTemp
            text={submitPopupData?.text}
            title={submitPopupData?.title}
            showImage={true}
            showText={true}
            showTitle={true}
            illus={submitPopupData?.illus}
            onClose={() => {
              dispatch(showSubmitPopup(false));
              submitPopupData?.onClose();
            }}
          />
        </div>
      )}
      {/*      <div className="popup-cont">
        <PopupTemp>
          <div className="popup_title_box_pricing">
            <h1>asdf</h1>
            <CustomIcon iconName={"humb"} className="title_icon" />
          </div>
          <div className="popup_content_box_pricing">
            <div>
              <CustomIcon iconName="illust_18" className="illus_pricing" />
            </div>
          </div>
        </PopupTemp>
      </div>*/}
      {infoPopupData && (
        <InfoPopup
          text={infoPopupData.text}
          type={infoPopupData.type}
          iconName={infoPopupData.iconName}
          // Make icon2 optional
          onClose={() => {
            document.querySelector(".info-popup").style.top = "-100px";
            setTimeout(() => {
              dispatch(showInfoPopup(false));
            }, 500);
          }}
        />
      )}
      {showUserLoginPopup && (
        <div className="popup-cont">
          <PopupCardUser
            onClose={() => {
              dispatch(toggleShowUserLoginPopup());
            }}
          />
        </div>
      )}

      {showCompanyLoginPopup && (
        <div className="popup-cont">
          <PopupCardCompany
            onClose={() => {
              dispatch(toggleShowCompanyLoginPopup());
            }}
          />
        </div>
      )}
    </>
  );
};

export default Layout;
