import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import CheckButton from "./CheckBtn";

const PageTitle = ({
  title,
  text,
  titleNumber,
  className,
  sectionTitle,
  page,
  setFilter,
  filter,
  adminTab,
}) => {
  return (
    <div className="page_title_box_cont">
      <div className={`page_title_box `}>
        {title && (
          <div className={`big_title ${className}`}>
            {(titleNumber || titleNumber == 0) && <span>{titleNumber}</span>}

            {title}
          </div>
        )}
        {sectionTitle && <h4>{sectionTitle}</h4>}
        {text && <h5>{text}</h5>}
      </div>
      {page === "admin" && adminTab === "job" && (
        <CheckButton
          iconUnchecked="check_off"
          iconChecked="check_on"
          label="Pending Posts"
          setIsChecked={(checked) => {
            console.log(checked);
            setFilter((prev) => {
              return { ...prev, pendingJobs: checked };
            });
          }}
          isChecked={filter?.pendingJobs}
        />
      )}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string, // title is now optional
  text: PropTypes.string,
  titleNumber: PropTypes.number,
  className: PropTypes.string,
  sectionTitle: PropTypes.string,
};

export default PageTitle;
