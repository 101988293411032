import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import CompanyCard from "../../compo/items/CompanyCard";
import { useEffect } from "react";
import { changeLanguage } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_URL } from "../../config";

export default function HeaderCompany({ imgSrc, data, className }) {
  const lang = useSelector((state) => state.lang);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1) {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "88px";
          el.style.borderBottom = "2px solid #00000010";
        });

        // document.querySelector(".header-mockup").style.display = "block";
      } else {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "120px";
          el.style.borderBottom = "1px solid transparent";
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL-  */}
      {lang === "en" ? (
        <div className="header_box">
          <div className="company_info_box">
            <CompanyCard
              className={"company_pic_header_NameLocation smaller_pic"}
              showCompanyPic={true}
              showJobName={false}
              showJobInfo={false}
              showCompanyName={true}
              showCountry={true}
              imgSrc={`${BACKEND_URL}${imgSrc}`}
              data={data}
              remote={"All"}
              page={"company"}
              country={data?.poster?.country}
              pref={data?.poster?.prefecture}
            />

            <Button
              text="Web"
              icon1="link"
              icon2="cart"
              className={["btn_c_line", "small_link_btn"]}
              onClick={() => {
                window.location.href = data?.poster?.url?.includes("https")
                  ? `${data?.poster?.url}`
                  : `https://${data?.poster?.url}`;
              }}
            />
            {/* <div className="separator"></div> */}

            {/* <div className="nav_link_box to_left">
              <Nav
                number={data?.poster?.active_posts_number}
                name={data?.poster?.active_posts_number > 1 ? "JOBS" : "JOB"}
                icon=""
                // hoverIcon="jobs_ani"
                className={["company_jobs_btn"]}
              />
            </div> */}
          </div>

          <div
            className={"logo_box logo_box_grey"}
            onClick={() => {
              window.location = "/";
            }}
          >
            <CustomIcon iconName="logo_type" />
            <CustomIcon iconName="logo_mark" className="logo_mark" />
          </div>
        </div>
      ) : (
        //* - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN -  */

        <div className="header_box">
          <div className="company_info_box">
            <CompanyCard
              className={"company_pic_header_NameLocation smaller_pic"}
              showCompanyPic={true}
              showJobName={false}
              showJobInfo={false}
              showCompanyName={true}
              showCountry={true}
              imgSrc={`${BACKEND_URL}${imgSrc}`}
              data={data}
              remote={"All"}
              page={"company"}
              country={data?.poster?.country}
              pref={data?.poster?.prefecture}
            />

            <Button
              text="Web"
              icon1="link"
              icon2="cart"
              className={["btn_c_line", "small_link_btn"]}
              onClick={() => {
                window.location.href = data?.poster?.url?.includes("https")
                  ? `${data?.poster?.url}`
                  : `https://${data?.poster?.url}`;
              }}
            />
            <div className="separator"></div>

            <div className="nav_link_box  to_left">
              <Nav
                name="ジョブ"
                icon=""
                number={data?.poster?.active_posts_number}
                // hoverIcon="jobs_ani"
                className={["company_jobs_btn jp_text"]}
              />
            </div>
          </div>

          <div
            className={"logo_box logo_box_grey"}
            onClick={() => {
              window.location = "/";
            }}
          >
            <CustomIcon iconName="logo_type" />
            <CustomIcon iconName="logo_mark" className="logo_mark" />
          </div>
        </div>
      )}
    </>
  );
}
