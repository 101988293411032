import "../styles/main.scss";

import { useState } from "react";
import axios from "axios";

import PopupCard from "../compo/items/PopupCardCompany";

function Signin() {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  function validateEmail(email) {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function validateForm(formData) {
    const error = {};

    // Check if all fields are present
    const requiredFields = ["email"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        error[field] = `${field} field is required`;
      }
    });
    // Additional validation for specific fields (if needed)
    if (!validateEmail(formData["email"])) {
      error["email"] = `Invalid email format`;
    }
    return error;
  }

  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(validateForm(data));
    let x = validateForm(data);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      axios
        .post(
          "http://178.16.142.189:8002/api/signin",
          { email: data?.email },
          {
            headers: {
              "Content-Type": "application/json", // Ensure correct content type
            },
          }
        )
        .then((response) => {
          setData();
        })
        .catch((error) => {
          console.error("Error:", error.response.data);
        });
    }
  };

  return (
    <PopupCard
      handleChange={(res1, res2) => {
        handleChange(res1, res2);
      }}
      errors={errors}
      data={data}
      login={true}
      className={"popup_lightbox_white"}
      handleSubmit={() => handleSubmit()}
    />
  );
}

export default Signin;
