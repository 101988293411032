// NavigationLink.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import Button from "../../compo/atoms/Button";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import PopupTab from "../atoms/PopupTab";
import { CreateDesigner, Signin } from "../../services/Designer";
import { UserLogin, UserSignup } from "../../validation";
import { useSelector, useDispatch } from "react-redux";
import { showInfoPopup, showSubmitPopup } from "../../redux/actions";

const PopupCardUser = ({ onClose }) => {
  const [login, setLogin] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of form submission
      handleSubmit();
    }
  };
  const handleSubmit = (close) => {
    // Assuming you have the necessary data to send in your request
    if (login) {
      setErrors(UserLogin(data));
      let x = UserLogin(data);
      // Assuming you have the necessary data to send in your request
      if (Object.keys(x).length === 0) {
        Signin(data?.login_email, "designer")
          .then((response) => {
            setData();
            if (response?.data?.message) {
              !close && onClose();
              close &&
                dispatch(showInfoPopup("success", "Email sent successfully"));

              dispatch(
                showSubmitPopup(
                  "Login Link Sent!",
                  <>
                    Your Access link has been sent to:
                    <br /> 
                    {data?.login_email}{" "}
                    <br /> 
                    Please click the link in the email to log in.
                    <br /> 
                  Note that the link will expire in 24 hours.
                    <br />
                    <Button
                      text="登録する"
                      icon1="send"
                      icon2="send_ani"
                      className="btn_c_line_grey resend_link_btn"
                      onClick={() => {
                        handleSubmit(true);
                      }}
                    />
                  </>,
                  "illust_13"
                )
              );
            }
          })
          .catch((error) => {
            dispatch(showInfoPopup("error", error?.response?.data?.error));
            setErrors({
              ...errors,
              login_email: error?.response?.data.error,
            });
          });
      } else {
        if (Object.keys(x).length === 1) {
          dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
        } else if (Object.keys(x).length >= 1) {
          dispatch(
            showInfoPopup(
              "error",
              "Please fill required fields with valid data"
            )
          );
        }
      }
    } else {
      setErrors(UserSignup(data));
      let x = UserSignup(data);
      // Assuming you have the necessary data to send in your request
      if (Object.keys(x).length === 0) {
        CreateDesigner(data, lang)
          .then((response) => {
            setData();
            onClose();
            dispatch(
              showSubmitPopup(
                "Thank you for signing up!",
                "A confirmation email has been sent to your inbox. Please check your email for a link to confirm your registration."
              )
            );
          })
          .catch((error) => {
            dispatch(showInfoPopup("error", error?.response?.data?.error));
            let errorFields = error?.response?.data?.fields;
            if (errorFields) {
              errorFields.forEach((element) => {
                setErrors({
                  ...errors,
                  [`${element}`]: error?.response?.data.error,
                });
              });
            }
          });
      } else {
        if (Object.keys(x).length === 1) {
          dispatch(showInfoPopup("error", x[Object.keys(x)[0]]));
        } else if (Object.keys(x).length >= 1) {
          dispatch(
            showInfoPopup(
              "error",
              "Please fill required fields with valid data"
            )
          );
        }
      }
    }
  };

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };
  return (
    <div className="popup_lightbox">
      <div className="popup_box">
        <div className="popup_close_box">
          <Button
            icon1="close"
            icon2="close"
            className={"btn_c_icn"}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className="popup_title_box">
          FOR DESIGNERS
          <CustomIcon iconName="designer" />
        </div>
        <div className="popup_content_box">
          <PopupTab
            tab1Name="Login"
            tab2Name="Signup"
            setLogin={(res) => {
              setLogin(res);
            }}
          />

          {/* - - - - - - - - - - - - - - - -  SIGNUP- - - - - - - - - - - - - -  */}
          {!login && (
            <div className="form_box">
              <CustomIcon iconName="illust_06" className="illus" />

              <PageTitle
                sectionTitle="Designers Signup"
                text="Receive your magic login link by email."
              />
              <div className="popup_input_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  focusedIconName="add"
                  defaultIconName="cal"
                  placeholder="username"
                  className="input_def username"
                  onChange={(e) => {
                    if (isUsernameValid(e) || e.length === 0) {
                      handleChange("username", e);
                    }
                  }}
                  value={data?.username}
                  error={errors?.username}
                  customIcon={
                    <p className="username-counter">{data?.username?.length}</p>
                  }
                  maxLength={16}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  focusedIconName="add"
                  defaultIconName="designer"
                  placeholder="Name"
                  className="input_def signup_username_input"
                  label=""
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                />
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  focusedIconName="M"
                  defaultIconName="mail"
                  placeholder="Email"
                  className="input_def"
                  label=""
                  onChange={(e) => handleChange("email", e)}
                  value={data?.email}
                  error={errors?.email}
                />
              </div>
              <Button
                text="Send"
                icon1="send"
                icon2="send_ani"
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          )}

          {/* - - - - - - - - - - - - - - - -  LOGIN- - - - - - - - - - - - - -  */}

          {login && (
            <div className="form_box">
              <CustomIcon iconName="illust_14" className="illus" />

              <PageTitle
                sectionTitle="Designers Login"
                text="Receive your magic login link by email. "
              />
              <div className="popup_input_box">
                <Input
                  handleKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  focusedIconName="M"
                  defaultIconName="mail"
                  placeholder="Email"
                  className="input_def"
                  label=""
                  onChange={(e) => handleChange("login_email", e)}
                  value={data?.login_email}
                  error={errors?.login_email}
                />
                <Button
                  text="Send"
                  icon1="send"
                  icon2="send_ani"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          )}
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
        </div>
      </div>
    </div>
  );
};

export default PopupCardUser;
