import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import CompanyPic from "../../compo/atoms/CompanyPic";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import Flag from "./Flag";
import countries from "../../data/countires.json";
import Companies from "../../pages/Companies";
import { useDispatch, useSelector } from "react-redux";
import prefs from "../atoms/prefs";
import prefectures from "../../data/prefectures.json";
import { useEffect } from "react";
import { BACKEND_URL } from "../../config";

export default function CompanyCard({
  className,
  showCompanyPic,
  showJobName,
  showJobInfo,
  showCountry,
  showFullName,
  showCompanyName,
  data,
  page,
  country,
  pref,
}) {
  const lang = useSelector((state) => state.lang);
  const prefecture = prefectures.find((el) => {
    return el.label === pref;
  });

  useEffect(() => {
    console.log(prefecture?.value);
  }, [prefecture]);
  function truncateText(text, maxLength) {
    if (text?.length <= maxLength || showFullName) {
      return text;
    } else {
      return text?.substring(0, maxLength - 3) + "...";
    }
  }
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function leaveFirstDecimal(number) {
    if (number >= 1000) {
      // Convert number to string
      let decimal = number / 1000;
      let numberString = decimal.toString();

      // Find the index of the first decimal point
      let decimalIndex = numberString.indexOf(".");

      // If decimal point exists, slice the string to leave the first decimal
      if (decimalIndex !== -1) {
        return `${numberString.slice(0, decimalIndex + 2)}k`; // Include the first decimal and the number before it
      }

      // If no decimal point, return the original number
      return `${numberString}k`;
    } else {
      return `${number}`;
    }
  }

  function findItemByKey(array, key, value) {
    if (value) {
      return array.find(
        (item) => item[key].toLowerCase() === value.toLowerCase()
      );
    } else {
      return "";
    }
  }
  return (
    <div className={`company_card_box ${className}`}>
      {showCompanyPic && (
        <CompanyPic
          className="company_card_pic"
          imgSrc={`${BACKEND_URL}${data?.poster?.image}`}
          onClick={(event) => {
            event.stopPropagation();
            window.location = `/pinjob.me/${data?.poster?.user.username}`;
          }}
        />
      )}

      <div className="company_info_box">
        {showJobName && (
          <div
            className={`job_name_box ${
              (page === "post" ||
                page === "company" ||
                page === "pins" ||
                page === "myJobs") &&
              "post_job_name_box"
            }`}
          >
            <span>{data?.title}</span>
            {data?.remote && capitalizeFirstLetter(data?.remote) !== "All" && (
              <Tags
                text={capitalizeFirstLetter(data?.remote)}
                className={
                  capitalizeFirstLetter(data?.remote) === "Hybrid" ||
                  data?.remote === "ハイブリッド"
                    ? "yellow_tag"
                    : capitalizeFirstLetter(data?.remote) === "Onsite" ||
                      data?.remote === "出社"
                    ? "grey_tag"
                    : ""
                }
              />
            )}
          </div>
        )}

        {showJobInfo && (
          <div
            className={`job_info_box ${page === "post" && "post_job_info_box"}`}
          >
            {data?.type}
            {page !== "pins" &&
              data?.maximum_salary &&
              data?.minimum_salary && <div className="spacer"></div>}
            {data?.maximum_salary && data?.minimum_salary && (
              <div className="salary_info">
                <span>
                  {data?.minimum_salary && (
                    <>${leaveFirstDecimal(data?.minimum_salary)}</>
                  )}
                </span>
                -
                <span>
                  {data?.maximum_salary && (
                    <>${leaveFirstDecimal(data?.maximum_salary)}</>
                  )}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="company_name_box">
          {showCompanyName && (
            <div className="company_name">
              {page !== "companies" && <div className="at">@</div>}
              <p>{truncateText(data?.poster?.company_name, 16)}</p>
            </div>
          )}

          {showCountry &&
            data?.remote !== "remote" &&
            data?.remote !== "リモート" && (
              <div className="location_info">
                {country ? (
                  <>
                    <div className="post-flag">
                      <Flag
                        code={findItemByKey(countries, "label", country)?.value}
                        height="100%"
                        className="w-5"
                        fallback={<span>Unknown</span>}
                      />
                    </div>
                    <p>
                      {page !== "companies"
                        ? truncateText(data?.city, 16)
                        : truncateText(data?.poster?.city, 16)}
                    </p>
                  </>
                ) : (
                  <>
                    <div className="post-flag">
                      <img
                        src={prefs[`svg${prefecture?.value}`]}
                        className="pref-logo"
                        alt="prefecture"
                      />
                    </div>
                    <p>{truncateText(prefecture?.label, 16)}</p>
                  </>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export function AdminCompanyCard({ className, data, page, adminTab }) {
  const lang = useSelector((state) => state.lang);
  const prefecture = {
    country: prefectures.find((el) => {
      return el.label === data?.poster?.prefecture;
    }),
    post: prefectures.find((el) => {
      return el.label === data?.prefecture;
    }),
  };
  function truncateText(text, maxLength) {
    if (text?.length <= maxLength) {
      return text;
    } else {
      return text?.substring(0, maxLength - 3) + "...";
    }
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function leaveFirstDecimal(number) {
    if (number >= 1000) {
      // Convert number to string
      let decimal = number / 1000;
      let numberString = decimal.toString();

      // Find the index of the first decimal point
      let decimalIndex = numberString.indexOf(".");

      // If decimal point exists, slice the string to leave the first decimal
      if (decimalIndex !== -1) {
        return `${numberString.slice(0, decimalIndex + 2)}k`; // Include the first decimal and the number before it
      }

      // If no decimal point, return the original number
      return `${numberString}k`;
    } else {
      return `${number}`;
    }
  }

  function findItemByKey(array, key, value) {
    if (value) {
      return array.find(
        (item) => item[key].toLowerCase() === value.toLowerCase()
      );
    } else {
      return "";
    }
  }
  return (
    <>
      <div className={`company_card_box ${className} admin_company_card_box`}>
        <CompanyPic
          className="admin_company_card_pic"
          imgSrc={`${BACKEND_URL}${data?.poster?.image}`}
          onClick={(event) => {
            event.stopPropagation();
            window.location = `/pinjob.me/${data?.poster?.user.username}`;
          }}
        />

        <div className="company_info_box">
          <div className="company_name_box">
            <div className="company_name">
              <p>{truncateText(data?.poster?.company_name, 16)}</p>
            </div>

            {
              <div className="location_info">
                {lang === "en" ? (
                  <>
                    <div className="post-flag">
                      <Flag
                        code={
                          findItemByKey(
                            countries,
                            "label",
                            data?.poster?.country
                          )?.value
                        }
                        height="100%"
                        className="w-5"
                        fallback={<span>Unknown</span>}
                      />
                    </div>
                    <p>{truncateText(data?.poster?.city, 16)}</p>
                  </>
                ) : (
                  <>
                    <div className="post-flag">
                      <img
                        src={prefs[`svg${prefecture?.country?.value}`]}
                        className="pref-logo"
                        alt="prefecture"
                      />
                    </div>
                    <p>{truncateText(prefecture?.country?.label, 16)}</p>
                  </>
                )}
              </div>
            }
          </div>
        </div>
        <Button
          className="admin_weblink_button"
          onClick={(e) => {
            e.stopPropagation();
            if (
              data?.poster?.url.startsWith("http://") ||
              data?.poster?.url.startsWith("https://")
            ) {
              // Open the external link in a new tab
              window.open(data?.poster?.url, "_blank");
            } else {
              window.open(`https://${data?.poster?.url}`, "_blank");
            }
          }}
          text={
            <>
              <CustomIcon
                iconName={"link"}
                className="admin_weblink_button_icon"
              />
              <p>Web</p>
            </>
          }
        />
        {adminTab === "job" && (
          <div className="admin-info-jobs-card">
            <div className="admin-info-jobs">
              {data?.poster?.active_posts_number}-{data?.poster?.posts_number}
            </div>
            <p className="admin-info-jobs-green-text">jobs</p>
          </div>
        )}
      </div>
      <div className={`company_card_box ${className}`}>
        <div className="company_info_box">
          {adminTab === "job" ? (
            <>
              <div
                className={`job_name_box ${
                  (page === "post" ||
                    page === "company" ||
                    page === "pins" ||
                    page === "myJobs") &&
                  "post_job_name_box"
                }`}
              >
                <span>{data?.title}</span>
                {data?.remote &&
                  capitalizeFirstLetter(data?.remote) !== "All" && (
                    <Tags
                      text={capitalizeFirstLetter(data?.remote)}
                      className={
                        capitalizeFirstLetter(data?.remote) === "Hybrid" ||
                        data?.remote === "ハイブリッド"
                          ? "yellow_tag"
                          : capitalizeFirstLetter(data?.remote) === "Onsite" ||
                            data?.remote === "出社"
                          ? "grey_tag"
                          : ""
                      }
                    />
                  )}
              </div>

              <div
                className={`job_info_box ${
                  page === "post" && "post_job_info_box"
                }`}
              >
                {data?.type}
                {page !== "pins" &&
                  data?.maximum_salary &&
                  data?.minimum_salary && <div className="spacer"></div>}
                {data?.maximum_salary && data?.minimum_salary && (
                  <div className="salary_info">
                    <span>
                      {data?.minimum_salary && (
                        <>${leaveFirstDecimal(data?.minimum_salary)}</>
                      )}
                    </span>
                    -
                    <span>
                      {data?.maximum_salary && (
                        <>${leaveFirstDecimal(data?.maximum_salary)}</>
                      )}
                    </span>
                  </div>
                )}
              </div>

              <div className="company_name_box">
                {data?.remote !== "remote" && data?.remote !== "リモート" && (
                  <div className="location_info">
                    {lang === "en" ? (
                      <>
                        <div className="post-flag">
                          <Flag
                            code={
                              findItemByKey(countries, "label", data?.country)
                                ?.value
                            }
                            height="100%"
                            className="w-5"
                            fallback={<span>Unknown</span>}
                          />
                        </div>
                        <p>
                          {page !== "companies"
                            ? truncateText(data?.city, 16)
                            : truncateText(data?.poster?.city, 16)}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="post-flag">
                          <img
                            src={prefs[`svg${prefecture?.post?.value}`]}
                            className="pref-logo"
                            alt="prefecture"
                          />
                        </div>
                        <p>{truncateText(prefecture?.post?.label, 16)}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div>
              <p>{data?.poster?.user?.username}</p>
              <p>{data?.poster?.user?.email}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
