import "../styles/main.scss";
import { ListSearch } from "../compo/templates/ListSearch";
import axios from "axios";
import { useEffect, useState } from "react";
import PageTitle from "../compo/atoms/PageTitle";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../config";
function Pins() {
  const designerData = useSelector((state) => state.designerData);
  const lang = useSelector((state) => state.lang);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState();
  const [jobsNo, setJobsNo] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (designerData && designerData?.pins?.length !== 0) {
      setLoading(true);
      axios
        .get(`${BACKEND_URL}/api/posts`, {
          params: {
            id_list: designerData?.pins,
            no_pagination: true,
          },
        })
        .then((response) => {
          if (response.data) {
            setData(response.data);
            setLoading(false);
            setJobsNo(response.data.length);
          }
        })
        .catch((error) => {
          setLoading(false);

          console.error(error); // handle errors here
        });
    } else {
      setData();
      setJobsNo(0);
      setLoading(false);
    }
  }, [designerData]);
  useEffect(() => {
    if (localStorage.getItem("user_role") !== "designer") {
      window.location = "/404";
    }
  }, []);
  return (
    <>
      {localStorage.getItem("user_role") === "designer" && (
        <div className="page_section_box_short">
          {lang === "en" ? (
            <PageTitle title="My Pins" titleNumber={jobsNo} />
          ) : (
            <PageTitle
              title="マイピン"
              titleNumber={jobsNo}
              className={"jp_text"}
            />
          )}

          <ListSearch
            className={"mypins_box"}
            data={data}
            filter={filter}
            setFilter={(val) => setFilter(val)}
            jobsNo={jobsNo}
            companies={false}
            search={false}
            page={"pins"}
            setData={(res) => setData(res)}
            setJobsNo={(res) => setJobsNo(res)}
            loading={loading}
          />
        </div>
      )}
    </>
  );
}

export default Pins;
