import "../styles/main.scss";
import { ListSearch } from "../compo/templates/ListSearch";
import TopPageIntro from "../compo/items/TopPageIntro";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BACKEND_URL } from "../config";
import { setCompanyData, setDesignerData } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { setToken, setUserRole } from "../redux/actions";
import { getDesignerDataRequest } from "../services/Designer";
import { getCompanyDataRequest } from "../services/Company";

function Jobs() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);
  const designerData = useSelector((state) => state.designerData);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [next, setNext] = useState();
  const [jobsNo, setJobsNo] = useState();
  const { password, username } = useParams();
  // get pins every time make a pagination request

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (filter) {
        setLoading(true);
        axios
          .get(`${BACKEND_URL}/api/posts`, {
            params: {
              type: filter?.jobType?.value,
              title: filter?.jobTitle?.value,
              country: filter?.country?.search,
              search: filter?.search,
              salary: filter?.salary,
              remote: [filter?.remote, filter?.hybrid, filter?.on_site],
              currency: filter?.currency?.value,
              prefecture: filter?.prefecture?.search,
              lang: lang,
            },
          })
          .then((response) => {
            if (response.data) {
              setData(response.data.results);
              setLoading(false);
            }
            setNext(response.data.next);
            setJobsNo(response.data.count);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error); // handle errors here
          });
      }
    }, 300); // Adjust the delay as needed (e.g., 1000 milliseconds = 1 second)

    return () => clearTimeout(debounceTimeout);
  }, [filter, lang]);

  const handlePagination = (link) => {
    axios
      .get(link, {
        params: {
          type: filter?.jobType?.value,
          title: filter?.jobTitle?.value,
          country: filter?.country?.search,
          search: filter?.search,
          salary: filter?.salary,
          remote: [filter?.remote, filter?.hybrid, filter?.on_site],
          currency: filter?.currency?.search,
          lang: lang,
        },
      })
      .then((response) => {
        if (response.data.results) {
          setData((prev) => {
            const prevIds = prev.map((el) => {
              return el.id;
            });
            const newIds = response.data.results.map((el) => {
              return el.id;
            });
            if (!prevIds.includes(newIds[0])) {
              prev.push(...response.data.results);
              setNext(response.data.next);
              setJobsNo(response.data.count);
            }
            return [...prev];
          });
        }
      })
      .catch((error) => {
        console.error(error); // handle errors here
      });
  };

  useEffect(() => {
    if (password) {
      let datas = JSON.stringify({
        username: username,
        password: password,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BACKEND_URL}/api/token/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: datas,
      };

      axios
        .request(config)
        .then((res) => {
          return res.data;

          //   console.log(jwtDecode(response.data.access));
        })
        .then((data) => {
          localStorage.setItem("token", data.access);

          if (data.type === "designer") {
            dispatch(setCompanyData(false));
            dispatch(setUserRole("designer"));
            dispatch(setToken(localStorage.getItem("token")));
            localStorage.setItem("user_role", "designer");
            getDesignerDataRequest(
              jwtDecode(localStorage.getItem("token"))?.user_id
            )
              .then((res) => {
                return res.data;
              })
              .then((data) => {
                dispatch(setDesignerData(data));
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (data.type === "company") {
            dispatch(setUserRole("company"));
            dispatch(setDesignerData(false));
            dispatch(setToken(localStorage.getItem("token")));
            localStorage.setItem("user_role", "company");
            getCompanyDataRequest()
              .then((res) => {
                return res.data.results;
              })
              .then((data) => {
                dispatch(setCompanyData(data[0]));
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error?.response?.data?.error);
        });
    }
  }, [dispatch, password, username]);
  return (
    <>
      <div className="content-hero">
        <ListSearch
          data={data}
          filter={filter}
          setFilter={(val) => setFilter(val)}
          jobsNo={jobsNo}
          designer={designerData}
          handlePagination={(res) => {
            handlePagination(res);
          }}
          next={next}
          pagination={true}
          loading={loading}
        />
      </div>
    </>
  );
}

export default Jobs;
