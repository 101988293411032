import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./compo/templates/Layout";
import Popups from "./pages/Popups_View";
import Home from "./pages/PageView";
import PlansPage from "./pages/PlansPage";
import SignupPage from "./pages/SignupPage";
import PostCreate from "./pages/PostCreate";
import JobDetail from "./pages/JobDetail";
import Company from "./pages/Company";
import Companies from "./pages/Companies";
import CompanyUpdate from "./pages/CompanyUpdate";
import Signin from "./pages/Signin";
import DesignerSignup from "./pages/DesignerSignup";
import Pins from "./pages/Pins";
import MyJobs from "./pages/MyJobs";
import DesignerUpdate from "./pages/DesignerUpdate";
import { Provider } from "react-redux"; // Import Provider
import store from "./redux/store";
import EntryForm from "./pages/EntryForm";
import EntryPage from "./pages/EntryPage";
import Soon from "./pages/Soon";
import Admin from "./pages/Admin";
import AdminLogin from "./pages/AdminLogin";
import Jobs from "./pages/Jobs";

const App = () => {
  return (
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <Layout>
            <Routes>
              <Route path="/entry-form" element={<EntryForm />} />
              <Route path="/entry-page" element={<EntryPage />} />
              <Route path="/:id" element={<Soon />} />
              <Route path="/:id/:id" element={<Soon />} />
              <Route path="/:id/:id/:id" element={<Soon />} />
              <Route path="/soon" element={<Soon />} />
              <Route path="/" element={<Home />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/admin/:username/:password" element={<Admin />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/home" element={<Home />} />
              <Route path="/home/:username/:password" element={<Home />} />
              <Route path="/popups" element={<Popups />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/job_detail/:postId" element={<JobDetail />} />
              <Route path="/choose_plan" element={<PlansPage />} />
              <Route path="/post/create" element={<PostCreate />} />
              <Route path="/post/update/:postId" element={<PostCreate />} />
              <Route path="/pinjob.me/:username" element={<Company />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/company/update" element={<CompanyUpdate />} />
              <Route path="/user/update" element={<DesignerUpdate />} />
              <Route
                path="/user/update/:password"
                element={<DesignerUpdate />}
              />
              <Route path="/signin" element={<Signin />} />
              <Route path="/designer/signup" element={<DesignerSignup />} />
              <Route path="/pins" element={<Pins />} />
              <Route path="/myjobs" element={<MyJobs />} />
              <Route
                path="/company/update/:password"
                element={<CompanyUpdate />}
              />
              <Route
                path="/job_detail/in_active/:postId"
                element={<JobDetail inActive={true} />}
              />
            </Routes>
          </Layout>
        </Provider>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
