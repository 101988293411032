import CustomIcon from "../data/IconCompo";
import { getCompanyDataRequest } from "../services/Company";
import { getDesignerDataRequest } from "../services/Designer";

export const increment = () => ({
  type: "INCREMENT",
});

export const decrement = () => ({
  type: "DECREMENT",
});
export const changeLanguage = (language) => ({
  type: "CHANGE_LANGUAGE",
  payload: language,
});
export const showInfoPopup = (
  type,
  text,
  time = 4000,
  icon,
  onFinish = () => {}
) => ({
  type: "SHOW_INFO_POPUP",
  payload: type
    ? {
        text: text,
        type: type,
        time: time,
        iconName: icon,
        onFinish: onFinish,
      }
    : false,
});
export const showSubmitPopup = (
  title,
  text,
  illus = "illust_17",
  onClose = () => {}
) => ({
  type: "SHOW_SUBMIT_POPUP",
  payload: title
    ? {
        title: title,
        text: text,
        illus: illus,
        onClose: () => {
          onClose();
        },
      }
    : false,
});
export const toggleShowUserLoginPopup = () => ({
  type: "SHOW_USER_LOGIN_POPUP",
});
export const toggleShowCompanyLoginPopup = () => ({
  type: "SHOW_COMPANY_LOGIN_POPUP",
});

export const setDesignerData = (data) => ({
  type: "SET_DESIGNER_DATA",
  payload: data,
});
export const setCompanyData = (data) => ({
  type: "SET_COMPANY_DATA",
  payload: data,
});
export const setToken = (data) => ({
  type: "SET_TOKEN",
  payload: data,
});
export const setUserRole = (data) => ({
  type: "SET_USER_ROLE",
  payload: data,
});
export const toggleShowDefaultHeader = (data) => ({
  type: "SHOW_DEFAULT_HEADER",
  payload: data,
});
export const toggleShowDefaultFooter = (data) => ({
  type: "SHOW_DEFAULT_FOOTER",
  payload: data,
});
