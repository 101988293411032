import "../styles/main.scss";
import "../styles/jobtext.scss";
import Header from "../compo/items/Header";
import Footer from "../compo/items/Footer";
import CompanyInfoDetailCard from "../compo/items/CompanyInfoDetailCard";
import HeaderCompany from "../compo/items/HeaderCompany";
import CompanyCard from "../compo/items/CompanyCard";
import Button from "../compo/atoms/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { BACKEND_URL } from "../config";
import { incrementViews } from "../services/Post";
import { useLocation } from "react-router-dom";
import { toggleShowDefaultHeader } from "../redux/actions";

function JobDetail({ designerData, inActive }) {
  const [companyData, setCompanyData] = useState({});
  const [postData, setPostData] = useState({});
  const { postId, approved } = useParams();
  const lang = useSelector((state) => state.lang);
  const token = useSelector((state) => state.token);
  const location = useLocation();
  const { admin } = location.state || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if (inActive) {
      if (
        admin ||
        (!admin && jwtDecode(localStorage.getItem("token"))?.user_id)
      ) {
        axios
          .get(`${BACKEND_URL}/api/posts`, {
            params: {
              id: postId,
              poster_id: admin
                ? jwtDecode(localStorage.getItem("admin_token"))?.user_id
                : jwtDecode(localStorage.getItem("token"))?.user_id,
              all: true,
            },
            headers: {
              "Content-Type": "application/json", // Ensure correct content type
              Authorization: admin
                ? `Bearer ${localStorage.getItem("admin_token")}`
                : `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
            },
          })
          .then((response) => {
            if (response.data) {
              setPostData(response.data.results[0]);
              setCompanyData(response.data.results[0].poster);
            }
          })
          .catch((error) => {
            console.log(error);
            // handle errors here
          });
      } else {
        window.location = "/";
      }
    } else {
      axios
        .get(`${BACKEND_URL}/api/posts`, {
          params: {
            id: postId,
          },
        })
        .then((response) => {
          if (response.data) {
            setPostData(response.data.results[0]);
            setCompanyData(response.data.results[0].poster);
          }
        })
        .catch((error) => {
          console.error(error); // handle errors here
        });
    }
  }, []);
  useEffect(() => {
    if (!inActive) {
      incrementViews(postData?.id, localStorage.getItem("token"))
        .then((response) => {
          if (response.data.message) {
            console.log(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [postData]);
  function findItemByKey(array, key, value) {
    if (value) {
      return array.find(
        (item) => item[key].toLowerCase() === value.toLowerCase()
      );
    } else {
      return "";
    }
  }
  function capitalize(str) {
    if (str == "on-site") {
      return "Onsite";
    } else {
      return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    }
  }
  useEffect(() => {
    document.querySelector(".job_text").innerHTML = postData?.job_description;
  });
  useEffect(() => {
    // Dispatch the reset action whenever the route changes
    dispatch(toggleShowDefaultHeader(true));
  }, []);
  return (
    <>
      <div className="content_box vertical">
        <div className="company_box">
          <CompanyCard
            showCompanyPic={true}
            showJobName={false}
            showJobInfo={false}
            showCompanyName={true}
            showCountry={true}
            showFullName={true}
            className={"company_info_ver"}
            data={postData}
            country={postData?.poster?.country}
            pref={postData?.poster?.prefecture}
            remote={"All"}
            page={"company"}
          />

          <Button
            text="Web"
            icon1="link"
            icon2="cart"
            onClick={() => {
              if (
                companyData?.url.startsWith("http://") ||
                companyData?.url.startsWith("https://")
              ) {
                // Open the external link in a new tab
                window.open(companyData?.url, "_blank");
              } else {
                window.open(`https://${companyData?.url}`, "_blank");
              }
            }}
            className={["btn_c_line", "small_link_btn"]}
          />

          <Button
            text={
              <div className="post-job-btn-cont">
                <p className="job-number">{companyData?.posts_number}</p>
                <p className="job-btn-text">JOBS</p>
              </div>
            }
            icon1="home"
            icon2="cart"
            className={"btn_c_line post-btn-job-number"}
            onClick={() => {
              window.location = `/pinjob.me/${companyData?.user?.username}`;
            }}
          />
        </div>

        <div className="job_box">
          <CompanyInfoDetailCard
            data={postData}
            country={postData?.country}
            pref={postData?.prefecture}
            fullName={true}
            designer={designerData}
            userId={token ? jwtDecode(token)?.user_id : ""}
            page={"company"}
            inActive={inActive}
          />
          <div className="job_text">{postData?.job_description}</div>
        </div>
      </div>
    </>
  );
}

export default JobDetail;
