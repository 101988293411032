import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import CompanyPic from "../atoms/CompanyPic";
import Button from "../atoms/Button";
import CompanyCard, { AdminCompanyCard, ies } from "./CompanyCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  showInfoPopup,
  showSubmitPopup,
  toggleShowUserLoginPopup,
  toggleShowCompanyLoginPopup,
  setDesignerData,
  setCompanyData,
  changeLanguage,
  UpdateDesignerData,
} from "../../redux/actions";
import {
  approvePost,
  deletePost,
  renewPost,
  stopPost,
} from "../../services/Post";
import { deleteUser } from "../../services/User";
import { getDesignerDataRequest } from "../../services/Designer";
import { getCompanyDataRequest } from "../../services/Company";

export default function JobsCard({
  className,
  data,
  jobsNo,
  onClick,
  setData,
  setJobsNo,
  setNext,
  handleFunction,
  page,
}) {
  const dispatch = useDispatch();
  const showUserLoginPopup = useSelector((state) => state.showUserLoginPopup);
  const designerData = useSelector((state) => state.designerData);
  const companyData = useSelector((state) => state.companyData);
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userRole);
  const nowDate = new Date();
  const startDate = new Date(data?.start_date);
  const endDate = new Date(data?.end_date);

  let x = designerData;

  const [pinned, setPinned] = useState(
    designerData?.pins ? designerData?.pins?.includes(data?.id) : false
  );
  function extractDate(timestamp) {
    // Parse the input timestamp to a Date object
    const date = new Date(timestamp);
    // Get the year, month, and day from the Date object
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Return the formatted date string
    return `${year}/${month}/${day}`;
  }

  function formatTimeDifference(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const diff = Math.round((now - time) / 1000); // Difference in seconds

    if (diff < 60) {
      return `now`;
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} `;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      return `Today`;
    } else {
      const days = Math.floor(diff / 86400);
      return `${days} day${days > 1 ? "s" : ""} `;
    }
  }

  useEffect(() => {
    setPinned(
      designerData?.pins ? designerData?.pins?.includes(data?.id) : false
    );
  }, [designerData, data?.id]);

  const handlePin = (event) => {
    if (designerData) {
      event.stopPropagation();
      // Define the data you want to send in the request
      if (designerData && data?.poster?.user?.id) {
        const requestData = {
          user_id: designerData?.user?.id, // Example user ID
          post_id: data?.id, // Example post ID
        };
        setPinned(!pinned);
        // Make a POST request using Axios
        axios
          .put(`${BACKEND_URL}/api/designer/pin`, requestData, {
            headers: {
              "Content-Type": "application/json", // Ensure correct content type
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you have a JWT token
            },
          })
          .then((response) => {
            if (response.data) {
              dispatch(showInfoPopup("success", response?.data?.message));

              getDesignerDataRequest(designerData?.user?.id)
                .then((res) => {
                  return res.data;
                })
                .then((data) => {
                  dispatch(setDesignerData(data));
                  if (page === "pins") {
                    handleFunction(data);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            // Handle success response here
          })
          .catch((error) => {
            console.error("Error:", error?.response?.data);
            if (error?.response?.status === 401) {
              dispatch(showInfoPopup("error", "Please login as designer "));
            } else if (error?.response?.data?.error) {
              dispatch(showInfoPopup("error", error.response.data.error));
            }
            setTimeout(() => {
              setPinned(!pinned);
            }, 500);
            // Handle error response here
          });
      }
    } else {
      event.stopPropagation();
      dispatch(toggleShowUserLoginPopup());
    }
  };
  const handleDelete = () => {
    deletePost(data?.id)
      .then((response) => {
        dispatch(showSubmitPopup(false));
        dispatch(showInfoPopup("success", "Post deleted successfully"));
        if (page === "myJobs") {
          setNext(`${BACKEND_URL}/api/posts`);
        }
        getCompanyDataRequest()
          .then((res) => {
            dispatch(setCompanyData(res.data.results[0]));
            handleFunction(data);
          })
          .catch((err) => {
            console.log(err);
          });
        // Handle successful response
      })
      .catch((error) => {
        console.error("Error:", error.response.data); // Handle error response
      });
  };
  const handleRenew = () => {
    renewPost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        if (page === "myJobs") {
          setNext(`${BACKEND_URL}/api/posts`);
        }
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };

  return (
    <div className="job_card_box" onClick={() => onClick()}>
      <div className={`job_card ${className}`}>
        <CompanyCard
          showCompanyPic={
            page !== "company" && page !== "myJobs" ? true : false
          }
          showJobName={page !== "companies" ? true : false}
          showJobInfo={page !== "companies" ? true : false}
          showCompanyName={
            page !== "company" && page !== "myJobs" ? true : false
          }
          showCountry={page !== "company" ? true : false}
          data={data}
          country={page !== "companies" ? data?.country : data?.poster.country}
          pref={
            page !== "companies" ? data?.prefecture : data?.poster.prefecture
          }
          page={page}
        />
        {page === "myJobs" && data?.is_approved && nowDate < endDate && (
          <div className="valid-post-date">
            <>
              {extractDate(data?.start_date)} -{" "}
              <span className="expired-text">
                {extractDate(data?.end_date)}
              </span>
            </>
          </div>
        )}
        {page === "myJobs" && data?.is_approved && nowDate > endDate && (
          <div className="expired-post-date">
            <>
              <span className="expire-in-text">Expired in: </span>{" "}
              {extractDate(data?.end_date)}
            </>
          </div>
        )}
        {page === "myJobs" && !data?.is_approved && (
          <div className="unapproved-post-date">Waiting for Approval</div>
        )}
      </div>
      {page !== "companies" ? (
        page !== "admin" && (
          <div className="job_actions_box">
            {localStorage.getItem("user_role") !== "company" &&
              userRole !== "company" &&
              page !== "myJobs" && (
                <Button
                  icon1="pin_in"
                  icon2="pin_off"
                  className={`btn_c_icn pin_it ${pinned && "btn_c_icn_yellow"}`}
                  onClick={(event) => handlePin(event)}
                />
              )}
            {page !== "companies" && page !== "myJobs" && (
              <div className="card_date">
                {formatTimeDifference(data?.created_at)}
              </div>
            )}
          </div>
        )
      ) : (
        <Button
          text={
            <div className="post-job-btn-cont">
              <p className="job-number">{data.poster.active_posts_number}</p>
              <p className="job-btn-text">JOBS</p>
            </div>
          }
          icon1="pins"
          // icon2="cart"
          className={"btn_c_line post-btn-job-number"}
          onClick={() => {}}
        />
      )}
      {
        // -------------------------------------------------------------------------------------- only for jobs admin
      }

      {page === "myJobs" && (
        <div className="myjobs-manage-cont">
          <div className="myjobs-manage-inner-cont">
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"pin_in"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.pins}</p>
            </div>
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"views"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.views}</p>
            </div>
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"clicks"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.clicks}</p>
            </div>
          </div>
          <div className="myjobs-manage-inner-cont">
            {page === "myJobs" && data?.is_approved && nowDate > endDate && (
              <Button
                icon1="timer"
                // icon2="cart"
                className={"btn_c_line  myjobs-manage-btn-renew"}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    showSubmitPopup(
                      "Renew post",
                      <Button
                        icon1="timer"
                        // icon2="cart"
                        text={<p>Renew</p>}
                        className={"btn_c  "}
                        onClick={() => {
                          handleRenew();
                        }}
                      />,
                      () => {
                        window.location.reload();
                      }
                    )
                  );
                }}
              />
            )}
            {page === "myJobs" && (
              <Button
                icon1="edit"
                // icon2="cart"
                className={"btn_c  myjobs-manage-btn-edit"}
                onClick={(e) => {
                  e.stopPropagation();
                  window.location = `/post/update/${data?.id}`;
                }}
              />
            )}

            <Button
              icon1="trash"
              // icon2="cart"
              className={"btn_c_line  myjobs-manage-btn-delete"}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  showSubmitPopup(
                    "Delete post",
                    <Button
                      icon1="trash"
                      // icon2="cart"
                      text={<p>Confirm Delete</p>}
                      className={"btn_c  myjobs-manage-btn-delete-2"}
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                  )
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

{
  // - - - - - - - - - -- -  - -- - - - - -  -- - - - -  --------------------------------------------------- Admin Jobs Cad
}

export function AdminJobsCard({
  className,
  data,
  jobsNo,
  onClick,
  setData,
  setJobsNo,
  setFilter,
  page,
  handlePagination,
  handleFunction,
  adminTab,
}) {
  const dispatch = useDispatch();
  const showUserLoginPopup = useSelector((state) => state.showUserLoginPopup);
  const designerData = useSelector((state) => state.designerData);
  const userRole = useSelector((state) => state.userRole);
  const nowDate = new Date();
  const startDate = new Date(data?.start_date);
  const endDate = new Date(data?.end_date);

  let x = designerData;

  const [pinned, setPinned] = useState(
    designerData?.pins ? designerData?.pins?.includes(data?.id) : false
  );
  function extractDate(timestamp) {
    // Parse the input timestamp to a Date object
    const date = new Date(timestamp);
    // Get the year, month, and day from the Date object
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Return the formatted date string
    return `${year}/${month}/${day}`;
  }
  function isPastDate(dateString) {
    const inputDate = new Date(dateString);
    const now = new Date();

    return inputDate < now;
  }
  function formatTimeDifference(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const diff = Math.round((now - time) / 1000); // Difference in seconds

    if (diff < 60) {
      return `now`;
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} `;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      return `Today`;
    } else {
      const days = Math.floor(diff / 86400);
      return `${days} day${days > 1 ? "s" : ""} `;
    }
  }

  useEffect(() => {
    setPinned(
      designerData?.pins ? designerData?.pins?.includes(data?.id) : false
    );
  }, [designerData, data?.id]);

  const handleDelete = () => {
    deletePost(data?.id, true)
      .then((response) => {
        dispatch(showSubmitPopup(false));
        dispatch(showInfoPopup("success", "Post deleted successfully"));
        handleFunction();
        getCompanyDataRequest()
          .then((res) => {
            dispatch(setCompanyData(res.data.results[0]));
            handleFunction(data);
          })
          .catch((err) => {
            console.log(err);
          });
        // Handle successful response
      })
      .catch((error) => {
        console.error("Error:", error.response.data); // Handle error response
      });
  };
  const handleRenew = () => {
    renewPost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };
  const handleApprove = () => {
    approvePost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        dispatch(showSubmitPopup(false));

        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };

  const handleStop = () => {
    stopPost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        dispatch(showSubmitPopup(false));

        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };
  return (
    <div className="job_card_box" onClick={() => onClick()}>
      <div className={`job_card ${className}`}>
        <AdminCompanyCard
          showCompanyPic={true}
          showCompanyName={true}
          showCountry={true}
          data={data}
          page={page}
          adminTab={adminTab}
        />

        {page === "myJobs" && data?.is_approved && nowDate < endDate && (
          <div className="valid-post-date">
            <>
              {extractDate(data?.start_date)} -{" "}
              <span className="expired-text">
                {extractDate(data?.end_date)}
              </span>
            </>
          </div>
        )}
        {page === "myJobs" && data?.is_approved && nowDate > endDate && (
          <div className="expired-post-date">
            <>
              <span className="expire-in-text">Expired in: </span>{" "}
              {extractDate(data?.end_date)}
            </>
          </div>
        )}
        {page === "myJobs" && !data?.is_approved && (
          <div className="unapproved-post-date">Waiting for Approval</div>
        )}
      </div>

      {
        // -------------------------------------------------------------------------------------- only for jobs admin
      }

      {
        <div className="myjobs-manage-cont">
          <div className="myjobs-manage-inner-cont">
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"pin_in"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.pins}</p>
            </div>
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"views"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.views}</p>
            </div>
            <div className="myjobs-manage-data">
              <CustomIcon
                iconName={"clicks"}
                className={"myjobs-manage-data-icon"}
              />
              <p>{data?.clicks}</p>
            </div>
          </div>
          <div className="admin-functions-cont">
            {data?.is_approved && (
              <div className="admin-valid-post-date">
                <>
                  {extractDate(data?.start_date)}-
                  <span className="expired-text">
                    {extractDate(data?.end_date)}
                  </span>
                </>
              </div>
            )}

            {(!data.is_approved || !data?.end_date) && (
              <Button
                icon1="ok"
                // icon2="cart"
                text=""
                className={"btn_c_line  admin-btn-approve"}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    showSubmitPopup(
                      "Approve post",
                      <Button
                        icon1="timer"
                        // icon2="cart"
                        text={<p>Approve</p>}
                        className={"btn_c  "}
                        onClick={() => {
                          handleApprove();
                        }}
                      />
                    )
                  );
                }}
              />
            )}
            {(!data.is_approved || !data?.end_date) && (
              <Button
                icon1="hand4"
                // icon2="cart"
                text=""
                className={"btn_c_line  admin-btn-stop"}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    showSubmitPopup(
                      "Delete post",
                      <Button
                        icon1="hand4"
                        // icon2="cart"
                        text={<p>Delete</p>}
                        className={"btn_c  "}
                        onClick={() => {
                          handleDelete();
                        }}
                      />
                    )
                  );
                }}
              />
            )}
            {data.is_approved && data?.end_date && (
              <>
                <Button
                  icon1="hand4"
                  // icon2="cart"
                  text=""
                  className={"btn_c_line  admin-btn-stop"}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      showSubmitPopup(
                        "Stop post",
                        <Button
                          icon1="hand4"
                          // icon2="cart"
                          text={<p>Stop</p>}
                          className={"btn_c  "}
                          onClick={() => {
                            handleStop();
                          }}
                        />
                      )
                    );
                  }}
                />
                <Button
                  icon1="trash"
                  // icon2="cart"
                  text=""
                  className={"btn_c_line  myjobs-manage-btn-delete"}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      showSubmitPopup(
                        "Delete post",
                        <Button
                          icon1="hand4"
                          // icon2="cart"
                          text={<p>Delete</p>}
                          className={"btn_c  "}
                          onClick={() => {
                            handleDelete();
                          }}
                        />
                      )
                    );
                  }}
                />
              </>
            )}
          </div>
        </div>
      }
    </div>
  );
}

export function AdminCompaniesCard({
  className,
  data,
  jobsNo,
  onClick,
  setData,
  setJobsNo,
  setFilter,
  page,
  handlePagination,
  handleFunction,
  adminTab,
}) {
  const dispatch = useDispatch();
  const showUserLoginPopup = useSelector((state) => state.showUserLoginPopup);
  const designerData = useSelector((state) => state.designerData);
  const userRole = useSelector((state) => state.userRole);
  const nowDate = new Date();
  const startDate = new Date(data?.start_date);
  const endDate = new Date(data?.end_date);

  let x = designerData;

  const [pinned, setPinned] = useState(
    designerData?.pins ? designerData?.pins?.includes(data?.id) : false
  );
  function extractDate(timestamp) {
    // Parse the input timestamp to a Date object
    const date = new Date(timestamp);
    // Get the year, month, and day from the Date object
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Return the formatted date string
    return `${year}/${month}/${day}`;
  }
  function isPastDate(dateString) {
    const inputDate = new Date(dateString);
    const now = new Date();

    return inputDate < now;
  }
  function formatTimeDifference(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const diff = Math.round((now - time) / 1000); // Difference in seconds

    if (diff < 60) {
      return `now`;
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} `;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      return `Today`;
    } else {
      const days = Math.floor(diff / 86400);
      return `${days} day${days > 1 ? "s" : ""} `;
    }
  }

  useEffect(() => {
    setPinned(
      designerData?.pins ? designerData?.pins?.includes(data?.id) : false
    );
  }, [designerData, data?.id]);

  useEffect(() => {}, []);

  const handleDelete = () => {
    deleteUser(data?.user?.id, true)
      .then((response) => {
        dispatch(showSubmitPopup(false));
        dispatch(showInfoPopup("success", "Post deleted successfully"));
        handleFunction();
        getCompanyDataRequest()
          .then((res) => {
            dispatch(setCompanyData(res.data.results[0]));
            handleFunction(data);
          })
          .catch((err) => {
            console.log(err);
          });
        // Handle successful response
      })
      .catch((error) => {
        console.error("Error:", error?.response?.data);
        dispatch(showInfoPopup("error", "Please try again later"));
        // Handle error response
      });
  };
  const handleRenew = () => {
    renewPost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };
  const handleApprove = () => {
    approvePost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        dispatch(showSubmitPopup(false));

        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };

  const handleStop = () => {
    stopPost(data?.id)
      .then((res) => {
        dispatch(showInfoPopup("success", res.message));
        dispatch(showSubmitPopup(false));

        handleFunction();
      })
      .catch((err) => {
        dispatch(showInfoPopup("error", err.response.data.message));
      });
  };
  useEffect(() => {
    console.log("jobs", adminTab);
  }, [adminTab]);
  return (
    <div className="job_card_box" onClick={() => onClick()}>
      <div className={`job_card ${className}`}>
        <AdminCompanyCard
          showCompanyPic={true}
          showCompanyName={true}
          showCountry={true}
          data={{ poster: { ...data } }}
          page={page}
          adminTab={adminTab}
        />

        {page === "myJobs" && data?.is_approved && nowDate < endDate && (
          <div className="valid-post-date">
            <>
              {extractDate(data?.start_date)} -{" "}
              <span className="expired-text">
                {extractDate(data?.end_date)}
              </span>
            </>
          </div>
        )}
        {page === "myJobs" && data?.is_approved && nowDate > endDate && (
          <div className="expired-post-date">
            <>
              <span className="expire-in-text">Expired in: </span>{" "}
              {extractDate(data?.end_date)}
            </>
          </div>
        )}
        {page === "myJobs" && !data?.is_approved && (
          <div className="unapproved-post-date">Waiting for Approval</div>
        )}
      </div>

      {
        // -------------------------------------------------------------------------------------- only for jobs admin
      }

      {
        <div className="myjobs-manage-cont">
          <div className="myjobs-manage-inner-cont">
            <div className="myjobs-manage-data-companies">
              <h3>{data?.posts_number}</h3>
              <p className="myjobs-manage-data-companies-green">JOBS</p>
            </div>
            <div className="myjobs-manage-data-companies">
              <h3 className="myjobs-manage-data-companies-green">
                {data?.active_posts_number}
              </h3>
              <p className="myjobs-manage-data-companies-green">Act</p>
            </div>
            <div className="myjobs-manage-data-companies ">
              <h3 className="myjobs-manage-data-companies-red">
                {parseInt(data?.expired_posts_number) +
                  parseInt(data?.unapproved_posts_number)}
              </h3>

              <p className="myjobs-manage-data-companies-red">Exp</p>
            </div>
            <div className="myjobs-manage-data-companies">
              <h3 className="myjobs-manage-data-companies-grey">
                {data?.posts_number -
                  data?.active_posts_number -
                  data?.expired_posts_number -
                  data?.unapproved_posts_number}
              </h3>
              <p className="myjobs-manage-data-companies-grey">Del</p>
            </div>
          </div>
          <div className="admin-functions-cont">
            {
              <>
                <Button
                  icon1="trash"
                  // icon2="cart"
                  text=""
                  className={"btn_c_line  myjobs-manage-btn-delete"}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      showSubmitPopup(
                        "Delete post",
                        <Button
                          icon1="hand4"
                          // icon2="cart"
                          text={<p>Delete</p>}
                          className={"btn_c  "}
                          onClick={() => {
                            handleDelete();
                          }}
                        />
                      )
                    );
                  }}
                />
              </>
            }
          </div>
        </div>
      }
    </div>
  );
}
